import React, { ForwardedRef, forwardRef, useCallback } from 'react';

import { Platform, usePlatform } from '@common/hooks/usePlatform';
import { useAppDispatch, useAppSelector } from '@common/store';

import { closeOverlay, openOverlay } from '@common/store/sideOverlay';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export type HeaderProps = {
  title?: React.ReactNode;
  liveMenu?: React.ReactNode;
  mainMenu?: React.ReactNode;
  // eslint-disable-next-line no-undef
  timeline?: GSAPTimeline | null;
};

const Header = forwardRef((props: HeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
  const platform = usePlatform();
  const dispatch = useAppDispatch();

  const hasScheduleOpen = useAppSelector((state) => state.sideoverlay.showOverlay);

  const openSchedule = useCallback(() => {
    dispatch(hasScheduleOpen ? closeOverlay() : openOverlay());
  }, [dispatch, hasScheduleOpen]);

  if (platform === Platform.MOBILE) {
    return <MobileHeader {...props} menuTimeline={props.timeline} ref={ref} />;
  }

  return <DesktopHeader {...props} hasScheduleOpen={hasScheduleOpen} openSchedule={openSchedule} />;
});

export default Header;
