import { Icon } from 'app/types/icons';

const Collapse: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.4661 17.802C10.2203 17.5583 10.2014 17.1749 10.4093 16.9097L10.4661 16.846L14.6709 12.6767L2.68182 12.676C2.30526 12.676 2 12.3733 2 12C2 11.6533 2.26321 11.3676 2.6023 11.3286L2.68182 11.324L14.6716 11.324L10.4661 7.15397C10.2203 6.91029 10.2014 6.52685 10.4093 6.26167L10.4661 6.19799C10.7118 5.95431 11.0986 5.93557 11.3661 6.14176L11.4303 6.19799L16.8003 11.522C17.0461 11.7657 17.065 12.1491 16.857 12.4143L16.8003 12.478L11.4303 17.802C11.164 18.066 10.7323 18.066 10.4661 17.802Z'
        fill={color}
        strokeWidth={0}
      />
      <rect x='20.5' y='2' width='1.5' height='20' rx='0.75' fill={color} strokeWidth={0} />
    </svg>
  );
};

export default Collapse;
