import { css, useTheme } from '@emotion/react';

type CardProps = {
  className?: string;
  opacity?: number;
  appearance?: 'light' | 'dark';
  onClick?: () => void;
};

const Card: React.FC<CardProps> = ({
  className,
  opacity = 0.1,
  appearance = 'light',
  onClick,
  children,
}) => {
  const theme = useTheme();
  const nativeOnClick = () => onClick?.();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;

        background: ${appearance === 'light'
          ? `rgba(250, 250, 250, ${opacity})`
          : `rgba(50, 50, 50, ${opacity})`};

        backdrop-filter: blur(${theme.blurs.normal});
        border-radius: 1em;
        cursor: ${onClick ? 'pointer' : 'default'};
      `}
      onClick={nativeOnClick}
      aria-hidden='true'
    >
      {children}
    </div>
  );
};

export default Card;
