import { parseSession, SessionResource } from '@nextempire/vle-common/lib/transforms/session';
import { parseUserRole, UserRoleResource } from '@nextempire/vle-common/lib/transforms/userRole';

import parse, { Attributes } from '@common/utils/parse';
import parseMeta from '@common/transforms/meta';
import { parseTag } from '@modules/event/transforms/tag';
import { parseCustomField } from '@modules/event/transforms/customField';
import { getUserId } from '@modules/auth/hooks/useAuth';

export enum CallStatus {
  AVAILABLE = 'AVAILABLE',
  IN_CALL = 'IN_CALL',
  DO_NOT_DISTURB = 'DO_NOT_DISTURB',
}

export const parseTags = (attributes: Attributes) => {
  return {
    ...attributes.custom('tag', (tag: any) => {
      return parse.one(tag, parseTag);
    }),
  };
};

export enum AttendanceType {
  ONLINE = 'ONLINE',
  ONSITE = 'ONSITE',
}

export const parseFields = (attributes: Attributes) => {
  return {
    value: attributes.string('value'),
    ...attributes.custom('customField', (customField: any) => {
      const parsedField = parse.one(customField, parseCustomField);
      return {
        key: parsedField.key,
        label: parsedField.label,
        required: parsedField.required,
        customFieldId: parsedField.id,
      };
    }),
  };
};

const parseUserSpeakers = (attributes: Attributes) => {
  return {
    id: attributes.string('id'),
    sessions: attributes.custom('sessions', (sessions) => {
      return parse.many(sessions, parseSession as any) as SessionResource[];
    }),
  };
};

export const parseUser = (attributes: Attributes) => {
  const id = attributes.id('id');
  const firstName = attributes.optional.string('firstName');
  const lastName = attributes.optional.string('lastName');
  return {
    id,
    callStatus: attributes.enum<typeof CallStatus>('callStatus', CallStatus),
    role: attributes.optional.custom('role', (role: any) => {
      return parse.one(role, parseUserRole as any) as UserRoleResource;
    }),
    avatarUrl: attributes.optional.string('avatarUrl'),
    firstName,
    lastName,
    isOnline: attributes.optional.boolean('active') || false,
    fullName: firstName && lastName && `${firstName} ${lastName}`,
    language: attributes.optional.string('language'),
    attendance: attributes.enum<typeof AttendanceType>('attendance', AttendanceType),
    tags: attributes.custom('tags', (tags: any) => {
      return parse.many(tags, parseTags);
    }),
    fields: attributes.custom('fields', (fields: any) => {
      return parse.many(fields, parseFields);
    }),
    company: attributes.optional.string('company'),
    function: attributes.optional.string('jobtitle'),
    linkedIn: attributes.optional.string('linkedin'),
    videoLink: attributes.optional.string('videolink'),
    isMe: getUserId() === id,
    accepted: attributes.optional.boolean('accepted'),
    privacyPolicyAccepted: attributes.optional.boolean('privacyPolicyAccepted'),
    unsubscribed: attributes.optional.boolean('unsubscribed'),
    speakers: attributes.optional.custom('speakers', (speakers) => {
      return parse.many(speakers, parseUserSpeakers);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseUser);
}

export function many(data: any) {
  return {
    items: parse.many(data.items, parseUser),
    meta: parseMeta(data.meta),
  };
}

export type UserSpeakerResource = ReturnType<typeof parseUserSpeakers>;
export type UserResource = ReturnType<typeof one>;
export type User = Omit<UserResource, 'id'>;

export default { one, many };
