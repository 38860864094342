import { Icon } from 'app/types/icons';

const List: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse cx='4.08333' cy='4.56522' rx='2.08333' ry='2.06522' fill={color} strokeWidth={0} />
      <ellipse cx='4.08333' cy='12' rx='2.08333' ry='2.06522' fill={color} strokeWidth={0} />
      <ellipse cx='4.08333' cy='19.4348' rx='2.08333' ry='2.06522' fill={color} strokeWidth={0} />
      <rect
        x='9.5'
        y='3.32609'
        width='12.5'
        height='2.47826'
        rx='1.23913'
        fill={color}
        strokeWidth={0}
      />
      <rect
        x='9.5'
        y='10.7609'
        width='12.5'
        height='2.47826'
        rx='1.23913'
        fill={color}
        strokeWidth={0}
      />
      <rect
        x='9.5'
        y='18.1956'
        width='12.5'
        height='2.47826'
        rx='1.23913'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default List;
