import { Icon } from 'app/types/icons';

const Chats: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.8466 5.03582C14.5776 4.87484 17.0925 4.88225 20.8231 5.03794C21.4812 5.06541 22 5.60823 22 6.26684V12.8487C22 13.508 21.4813 14.0511 20.8225 14.0777C20.2661 14.1001 19.7367 14.1194 19.2263 14.1353C19.0872 14.1395 19.1052 14.716 19.1472 15.3786C19.1814 15.9174 18.5768 16.2001 18.1776 15.8367C17.4947 15.215 16.8444 14.6409 16.461 14.3646V8.57885C16.461 7.80759 15.8544 7.17134 15.0839 7.13809C13.0709 7.05124 11.3597 7.00617 9.67267 7.00059V6.26399C9.67267 5.60653 10.1897 5.06416 10.8466 5.03582Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M14.1599 8.04644C10.0921 7.87094 7.35029 7.87901 3.28303 8.04876C2.5656 8.0787 2 8.6705 2 9.38855V16.5644C2 17.2831 2.56551 17.8752 3.28369 17.9042C3.89032 17.9287 4.46747 17.9497 5.02393 17.967C5.17561 17.9716 5.15605 18.6001 5.11021 19.3225C5.07293 19.9099 5.73213 20.2182 6.16735 19.8219C7.2084 18.8741 8.18018 18.0278 8.38385 18.0288C10.1996 18.0356 11.9906 17.9953 14.158 17.9057C14.8753 17.8761 15.4397 17.2844 15.4397 16.5664V9.38544C15.4397 8.66865 14.876 8.07734 14.1599 8.04644Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Chats;
