import React, { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';

import Heading from '@common/components/Heading';
import eventTheme from '@common/styles/eventTheme';
import GlobalTheme from '@common/components/GlobalTheme';
import EventBackground from '@modules/event/components/EventBackground';

export default function Error500() {
  useEffect(() => {
    setTimeout(() => window.location.reload(), 60_000);
  }, []);

  return (
    <ThemeProvider theme={eventTheme()}>
      <GlobalTheme />
      <EventBackground appearance='dark'>
        <Heading>
          Please stand by and do not refresh the page.
          <br />
          We will be back shortly.
        </Heading>
      </EventBackground>
    </ThemeProvider>
  );
}
