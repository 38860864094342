/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';

import { mediaQuery } from '@common/styles/mediaQuery';

type HeadingProps = {
  align?: 'left' | 'right' | 'center';
  size?: 'h1' | 'h2' | 'h3' | 'subtitle';
  color?: 'primary' | 'textLight' | 'disabled' | 'disabledText';
};

const Heading: React.FC<HeadingProps> = ({
  size = 'h1',
  color = 'primary',
  children,
  ...restProps
}) => {
  const theme = useTheme();

  switch (size) {
    case 'h1':
      return (
        <h1
          css={css`
            color: ${theme.colors[color]};
            margin: 0;
            margin-bottom: 1em;
            font-size: ${theme.fontSizes.mobile.h1};
            font-weight: ${theme.fontWeights[size]};
            line-height: 1.11;
            ${mediaQuery(
              's',
              css`
                font-size: ${theme.fontSizes.h1};
              `,
            )}
          `}
          {...restProps}
        >
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2
          css={css`
            color: ${theme.colors[color]};
            margin: 0;
            margin-bottom: 0.8em;
            font-size: ${theme.fontSizes.mobile.h2};
            font-weight: ${theme.fontWeights[size]};
            ${mediaQuery(
              's',
              css`
                font-size: ${theme.fontSizes.h2};
              `,
            )}
          `}
          {...restProps}
        >
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3
          css={css`
            color: ${theme.colors[color]};
            margin: 0;
            font-size: ${theme.fontSizes.mobile.h3};
            font-weight: ${theme.fontWeights[size]};
            ${mediaQuery(
              's',
              css`
                font-size: ${theme.fontSizes.h3};
              `,
            )}
          `}
          {...restProps}
        >
          {children}
        </h3>
      );
    case 'subtitle':
      return (
        <h4
          css={css`
            color: ${theme.colors[color]};
            margin: 0;
            margin-bottom: 0.5em;
            font-size: ${theme.fontSizes[size]};
            font-weight: ${theme.fontWeights[size]};
          `}
          {...restProps}
        >
          {children}
        </h4>
      );
    default:
      return (
        <h1
          css={css`
            color: ${theme.colors[color]};
            margin: 0;
            margin-bottom: 1em;
            font-size: ${theme.fontSizes[size]};
            font-weight: ${theme.fontWeights[size]};
          `}
          {...restProps}
        >
          {children}
        </h1>
      );
  }
};

export default Heading;
