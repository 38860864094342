/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserResource } from '@modules/user/transforms/user';

export interface AuthState {
  isInitializing: boolean;
  isLoading: boolean;
  isAuthorized: boolean;
  isAccepted: boolean;
  isRegistered: boolean;
  privacyPolicyAccepted: boolean;
  hasRequiredFieldsFilled: boolean;
  user?: UserResource;
  error?: any;
}

const initialState: AuthState = {
  isInitializing: true,
  isLoading: false,
  isAuthorized: false,
  isAccepted: false,
  isRegistered: false,
  hasRequiredFieldsFilled: false,
  privacyPolicyAccepted: false,
  user: undefined,
  error: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInitializing: (state, action: PayloadAction<boolean>) => {
      state.isInitializing = action.payload;
    },
    setAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAccepted: (state, action: PayloadAction<boolean>) => {
      state.isAccepted = action.payload;
    },
    setRegistered: (state, action: PayloadAction<boolean>) => {
      state.isRegistered = action.payload;
    },
    setRequiredFieldsFilled: (state, action: PayloadAction<boolean>) => {
      state.hasRequiredFieldsFilled = action.payload;
    },
    setPrivacyPolicyAccepted: (state, action: PayloadAction<boolean>) => {
      state.privacyPolicyAccepted = action.payload;
    },
    setUser: (state, action: PayloadAction<UserResource | undefined>) => {
      state.user = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload === 'Unauthorized' ? 'vle_error_token_expired' : action.payload;
    },
  },
});

export const {
  setInitializing,
  setLoading,
  setAuthorized,
  setAccepted,
  setRegistered,
  setRequiredFieldsFilled,
  setPrivacyPolicyAccepted,
  setUser,
  setError,
} = authSlice.actions;

export default authSlice.reducer;
