import { createContext, useCallback, useContext, useState } from 'react';

import transform, { EventResource } from '@modules/event/transforms/event';

import fetch from '@common/utils/fetch';
import { writeEventId } from '@modules/auth/utils';

const eventContext = createContext<{
  event: EventResource | undefined;
  setEvent: (data: EventResource) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ event: undefined, setEvent: () => {} });

export const EventProvider = ({
  children,
  event,
}: {
  children: React.ReactNode;
  event?: EventResource;
}) => {
  if (event) writeEventId(event.id);
  const [currentEvent, setCurrentEvent] = useState(event);

  const setEvent = (data: EventResource) => {
    setCurrentEvent(data);
  };

  return (
    <eventContext.Provider value={{ event: currentEvent, setEvent }}>
      {children}
    </eventContext.Provider>
  );
};

export const useEvents = () => {
  const { event, setEvent } = useContext(eventContext);

  const refreshEvent = useCallback(
    async (lang?: string) => {
      const { body } = await fetch(`/events/${event?.id}?lang=${lang}`);
      setEvent(transform.one(body));
    },
    [event?.id, setEvent],
  );

  return {
    event,
    refreshEvent,
  };
};

export const useEvent = () => {
  const { event } = useEvents();

  return event;
};

export const useEventId = () => {
  const event = useEvent();

  return event?.id;
};

export default useEvent;
