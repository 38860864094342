import { css } from '@emotion/react';

import Button from '@common/components/Button';

import { TagResource } from '@modules/event/transforms/tag';

type TagCloudProps = {
  tags: TagResource[];
  selected?: TagResource[];
  left?: boolean;
  inRow?: boolean;
  onTagClick?: (tag: TagResource) => void;
};

const TagCloud = ({ tags, selected, left, inRow, onTagClick }: TagCloudProps) => (
  <div
    css={css`
      width: 100%;
      display: flex;
      row-gap: 1.313em;
      column-gap: 1.25em;
      flex-direction: row;
      ${/* eslint-disable no-nested-ternary */ ''}
      justify-content: ${left ? 'flex-start' : inRow ? 'flex-end' : 'center'};
      flex-wrap: wrap;
    `}
  >
    {tags?.map((tag) => {
      return (
        <Button
          key={tag.id}
          outline={!selected?.find((t) => tag.id === t.id)}
          label={tag.label}
          size='s'
          disableMinWidth
          onClick={() => onTagClick?.(tag)}
        />
      );
    })}
  </div>
);

export default TagCloud;
