import { Icon } from 'app/types/icons';

const Calendar: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.44872 7.5V13.25V19C4.44872 19.5523 4.88495 20 5.42308 20H11.7564H18.5769C19.115 20 19.5513 19.5523 19.5513 19V13.25V7.5C19.5513 6.94772 19.115 6.5 18.5769 6.5H17.8462V4.5H18.5769C20.1913 4.5 21.5 5.84315 21.5 7.5V13.25V19C21.5 20.6569 20.1913 22 18.5769 22H11.7564H5.42308C3.80871 22 2.5 20.6569 2.5 19V13.25V7.5C2.5 5.84315 3.80871 4.5 5.42308 4.5H6.15385V6.5H5.42308C4.88495 6.5 4.44872 6.94772 4.44872 7.5Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M6.15387 7.75V3.25C6.15387 2.55964 6.69916 2 7.37182 2C8.04447 2 8.58977 2.55964 8.58977 3.25V4.5H15.4103V3.25C15.4103 2.55964 15.9556 2 16.6282 2C17.3009 2 17.8462 2.55964 17.8462 3.25V7.75C17.8462 8.44036 17.3009 9 16.6282 9C15.9556 9 15.4103 8.44036 15.4103 7.75V6.5H8.58977V7.75C8.58977 8.44036 8.04447 9 7.37182 9C6.69916 9 6.15387 8.44036 6.15387 7.75Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Calendar;
