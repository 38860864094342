import { useState, useEffect, useMemo } from 'react';
import { useEvent, usePusher } from '@harelpls/use-pusher';
import dayjs from 'dayjs';

import { PUSHER_SESSION_UPDATE } from '@config/pusher';
import { useCurrentTime } from '@common/hooks/useCurrentTime';
import { useScheduledSessions } from '@modules/schedule/hooks/useSessions';
import { createAlert, updateAlert, ALERT_TYPES } from '@common/utils/alerts';
import useCurrentEvent from '@modules/event/hooks/useEvent';

import { useAppSelector } from '@common/store';

const DEFAULT_REMINDER_TIME = 15;

export const useSessionReminders = () => {
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);
  const event = useCurrentEvent();
  const { data: rawSessions, refetch } = useScheduledSessions({ page: 1, limit: 99 });
  const sessions = useMemo(() => rawSessions?.items ?? [], [rawSessions]);
  const [remindedSessionsIds, setRemindedSessionsIds] = useState<string[]>([]);
  const currentTime = useCurrentTime();
  const Pusher = usePusher();
  const globalChannel = Pusher?.client?.subscribe(`private-${event?.id}`);

  const { privacyPolicyAccepted } = useAppSelector((state) => state.auth);

  useEvent(globalChannel, PUSHER_SESSION_UPDATE, () => refetch());

  useEffect(() => {
    if (isAuthorized) {
      sessions.forEach((session) => {
        const reminderTime = session.reminderTime || DEFAULT_REMINDER_TIME;

        // update session reminder if it exists
        updateAlert({
          type: ALERT_TYPES.REMINDER,
          pinned: true,
          decay: false,
          props: { session },
        });

        if (
          (session.reminder || session.mandatory) &&
          dayjs(currentTime).isAfter(dayjs(session.startsAt).subtract(reminderTime, 'minutes')) &&
          dayjs(currentTime).isBefore(dayjs(session.endsAt)) &&
          !remindedSessionsIds.includes(session.id) &&
          privacyPolicyAccepted
        ) {
          setRemindedSessionsIds((remindedSessions) => [...remindedSessions, session.id]);
          createAlert({
            type: ALERT_TYPES.REMINDER,
            pinned: true,
            decay: false,
            props: { session },
          });
        }
      });
    }
  }, [currentTime, sessions, remindedSessionsIds, privacyPolicyAccepted, isAuthorized]);

  return sessions;
};

export const SessionReminders: React.FC = () => {
  useSessionReminders();

  return null;
};

export default {
  useSessionReminders,
};
