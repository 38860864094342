/* eslint-disable security/detect-object-injection */
/* eslint-disable no-restricted-globals */
import { decode } from 'jsonwebtoken';
import cookie from 'js-cookie';
import { NextPageContext } from 'next';

import { UserResource } from '@modules/user/transforms/user';
import { EventResource } from '@modules/event/transforms/event';
import { TokenResource } from '@modules/auth/transforms/token';

export const REFRESH_COOKIE = '_vle_refresh';
export const ACCESS_COOKIE = '_vle_access';
export const ACCESS_COOKIE_EXPIRY = '_vle_access_expiry';
export const EVENT_ID_COOKIE = '_vle_event_id';
export const CONSENT_COOKIE = '_vle_consent';

function getTokenExpiresInDays(token: string) {
  const decoded = decode(token);
  const expiresAt = new Date(0);

  if (
    decoded &&
    typeof decoded === 'object' &&
    (typeof decoded.exp === 'string' || typeof decoded.exp === 'number')
  ) {
    const exp = typeof decoded.exp === 'string' ? parseInt(decoded.exp, 10) : decoded.exp;
    expiresAt.setUTCSeconds(exp);
  }

  const expiresIn = Math.abs(expiresAt.getTime() - new Date().getTime());
  return Math.ceil(expiresIn / (1000 * 60 * 60 * 24));
}

// eslint-disable-next-line security/detect-unsafe-regex
const secure = process.browser
  ? !/(lvh\.me|localhost)(:\d+)?$/.test(location.host) && location.protocol === 'http:'
  : true;

// Token persistence
export function writeToken(
  tokens: Partial<TokenResource>,
  tokenName: 'accessToken' | 'refreshToken',
  tokenKey: string,
  tokenExpiresName?: 'accessTokenExpiresAt' | 'refreshTokenExpiresAt',
  tokenExpiryKey?: string,
) {
  const token = tokens[tokenName];
  if (token) {
    const expiry = new Date();
    const expires = tokenExpiresName ? tokens[tokenExpiresName] : undefined;
    if (expires) {
      expiry.setMilliseconds(expiry.getMilliseconds() + expires);
    } else {
      // this should never occur
      expiry.setDate(expiry.getDate() + getTokenExpiresInDays(token));
    }

    if (tokenExpiryKey) localStorage.setItem(tokenExpiryKey, expiry.toISOString());
    cookie.set(tokenKey, token, {
      expires: expiry,
      sameSite: secure ? 'None' : 'Lax',
      secure,
    });
  } else {
    cookie.remove(tokenKey);
  }
}

export function writeTokens(tokens: Partial<TokenResource>) {
  writeToken(tokens, 'refreshToken', REFRESH_COOKIE);
  writeToken(tokens, 'accessToken', ACCESS_COOKIE, 'accessTokenExpiresAt', ACCESS_COOKIE_EXPIRY);
}

export const readRefreshToken = () => cookie.get(REFRESH_COOKIE);
export const readAccessToken = () => cookie.get(ACCESS_COOKIE);
export const readAccessTokenExpiry = (): Date | undefined => {
  const expiry = localStorage.getItem(ACCESS_COOKIE_EXPIRY);
  if (expiry) return new Date(expiry);
  return undefined;
};

// Event id token persistence
export const writeEventId = (token?: string) => {
  if (token) {
    cookie.set(EVENT_ID_COOKIE, token, {
      expires: getTokenExpiresInDays(token),
      sameSite: secure ? 'None' : 'Lax',
      secure,
    });
  } else {
    cookie.remove(EVENT_ID_COOKIE);
  }
};

export const readEventId = () => cookie.get(EVENT_ID_COOKIE);

export const writeConsent = (status: 'accepted' | 'declined') => {
  cookie.set(CONSENT_COOKIE, status, {
    sameSite: secure ? 'None' : 'Lax',
    secure,
  });
};

export const readConsent = () => cookie.get(CONSENT_COOKIE);

export function absoluteUrl(req: NextPageContext['req'], setLocalhost?: string) {
  let protocol = 'https:';
  let host = req ? req.headers['x-forwarded-host'] || req.headers.host : window.location.host;
  if (host && host.indexOf('localhost') > -1) {
    if (setLocalhost) host = setLocalhost;
    protocol = 'http:';
  }

  return {
    protocol,
    host,
    origin: `${protocol}//${host}`,
    url: req,
  };
}

export function checkRequiredFieldsFilled(user: UserResource, event: EventResource): boolean {
  return (
    user.fields.reduce((amount, field) => (field.required ? amount + 1 : amount), 0) >=
    (event.requiredCustomFields ?? 0)
  );
}
