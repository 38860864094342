import { useRef, useState, useEffect } from 'react';
import { css, useTheme } from '@emotion/react';

import Blur from '@common/components/Blur';
import { mediaQuery } from '@common/styles/mediaQuery';

import { Event } from '@modules/event/transforms/event';

type EventBackgroundProps = {
  blur?: boolean;
  event?: Event;
  absolute?: boolean;
  hasSidebar?: boolean;
  autoResize?: boolean;
  appearance?: 'light' | 'dark';
};

const EventBackground: React.FC<EventBackgroundProps> = ({
  blur = false,
  absolute = false,
  hasSidebar = false,
  autoResize = false,
  appearance = 'light',
  event,
  children,
}) => {
  const theme = useTheme();
  const background = event?.themeBackgroundUrl
    ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${event?.themeBackgroundUrl})`
    : event?.themeBackgroundColor || theme.colors.eventBackground;

  const parent = useRef<HTMLDivElement>(null);
  const [fullHeight, setFullHeight] = useState(false);

  useEffect(() => {
    if (autoResize) {
      const handler = () => {
        const childHeight = parent.current?.children[1]?.getBoundingClientRect().height;
        if (!childHeight) return;

        setFullHeight(window.innerHeight > childHeight + 30);
      };

      handler();
      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
    }

    return () => null;
  }, [autoResize]);

  return (
    <div
      ref={parent}
      css={css`
        height: fit-content;
        overflow: scroll;
        display: flex;
        justify-content: center;
        align-items: center;
        ${fullHeight && 'min-height: 100%;'}
        ${absolute && 'position: absolute;left: 0;top: 0;width: 100vw;height: 100vh;z-index: 0;'};
      `}
    >
      <div
        css={css`
          background: ${background};
          background-repeat: repeat-y;
          background-size: cover;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
        `}
      />
      {blur && event?.themeBackgroundUrl && <Blur appearance={appearance} />}
      <div
        css={css`
          width: 100vw;
          min-height: 100vh;
          padding: 0 1em;

          ${mediaQuery(
            's',
            css`
              padding: ${hasSidebar ? '0 calc(10% + 104px) 0 10%' : '0 10%'};
              display: flex;
              justify-content: center;
              align-items: center;
            `,
          )}
        `}
      >
        {children}
      </div>
    </div>
  );
};

export default EventBackground;
