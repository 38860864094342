import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import { writeConsent, readConsent } from '@modules/auth/utils';

const CookieConsent: React.FC = () => {
  const { t } = useTranslation();
  const [consent, setConsent] = useState(readConsent());

  const onConsent = (status: 'accepted' | 'declined') => {
    writeConsent(status);
    setConsent(status);
  };

  return (
    <div
      css={css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: black;
        color: white;
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;
        z-index: 999999;
        font-size: 13px;
        color: #f9f9f9;
        transition: all 0.5s ease-in-out;
        ${!!consent && 'transform: translateY(100%);'}
      `}
    >
      <span
        css={css`
          margin-bottom: 0.5em;
        `}
      >
        {t('cookiebar')}
      </span>
      <span className='cookie-bar__actions'>
        <button
          css={css`
            color: #f9f9f9;
            opacity: 0.5;
            margin-right: 1em;
            cursor: pointer;
            background: transparent;
            border: none;
          `}
          className='cookie-bar__decline'
          onClick={() => onConsent('declined')}
        >
          {' '}
          {t('cookiebar_decline')}
        </button>
        <button
          css={css`
            display: inline-block;
            padding: 6px 7px 7px 7px;
            background: forestgreen;
            color: white;
            border-radius: 3px;
            line-height: 12px;
            cursor: pointer;
            border: none;
          `}
          onClick={() => onConsent('accepted')}
        >
          {t('cookiebar_accept')}
        </button>
      </span>
    </div>
  );
};

export default CookieConsent;
