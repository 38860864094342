import dayjs from 'dayjs';
import { useEffect } from 'react';
import type { SessionResource } from '@nextempire/vle-common/lib/transforms/session';

export enum SESSION_STATES {
  NOT_STARTED = 'NOT_STARTED',
  LIVE = 'LIVE',
  PASSED = 'PASSED',
}

type SessionDates = Pick<SessionResource, 'startsAt' | 'endsAt'>;

export function getStatus(session?: SessionDates): SESSION_STATES {
  if (!session) return SESSION_STATES.NOT_STARTED;
  if (dayjs().isBefore(session.startsAt)) {
    return SESSION_STATES.NOT_STARTED;
  }
  if (dayjs().isBetween(session.startsAt, session.endsAt)) {
    return SESSION_STATES.LIVE;
  }
  return SESSION_STATES.PASSED;
}

export function useSessionStatus(
  session: SessionDates | undefined,
  callback: (status: SESSION_STATES) => void,
) {
  useEffect(() => {
    const check = () => callback(getStatus(session));

    check();
    const i = setInterval(check, 500);
    return () => clearInterval(i);
  }, [session, callback]);
}
