import { Icon } from 'app/types/icons';

const Phone: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.43533 10.6158C7.53231 9.36307 8.41793 9.06903 9.27572 8.25822C10.4241 7.17423 10.358 7.01938 8.77984 5.43952C7.95511 4.61653 6.75977 3.26982 6.04988 2.61212C5.13293 1.76129 4.87716 1.79957 3.9202 2.63126C3.28629 3.15256 2.78308 3.81489 2.45077 4.56532C2.11845 5.31576 1.96623 6.13351 2.00627 6.95326C1.94189 12.2253 9.50539 19.6548 14.4625 21.5479C15.2778 21.8846 16.1539 22.0493 17.0358 22.0316C17.8553 22.0712 18.6727 21.9188 19.4227 21.5865C20.1728 21.2542 20.8349 20.7512 21.3561 20.1176C22.1965 19.1607 22.2261 18.8997 21.3752 17.9862C20.7175 17.2763 19.4213 16.0288 18.5478 15.258C16.8862 13.793 16.8079 13.6137 15.7291 14.7621C14.9201 15.6199 14.6243 16.502 13.3715 15.6007C11.5982 14.0708 9.94777 12.4041 8.43533 10.6158V10.6158Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Phone;
