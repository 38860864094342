import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import Heading from '@common/components/Heading';
import TagCloud from '@modules/event/components/TagCloud';
import Labeled from '@common/components/Labeled';
import AvatarUploadCard from '@modules/user/components/AvatarUploadCard';
import Button from '@common/components/Button';
import Input from '@common/components/Input';
import Select from '@common/components/Select';

import useEvent from '@modules/event/hooks/useEvent';
import { useCustomFields } from '@modules/event/hooks/useCustomFields';
import { useTags } from '@modules/event/hooks/useTags';
import { useLogout } from '@modules/auth/hooks/useLogout';

import { AttendanceType, CallStatus, UserResource } from '@modules/user/transforms/user';
import { TagResource } from '@modules/event/transforms/tag';
import { CustomFieldType } from '@modules/event/transforms/customField';
import { mediaQuery } from '@common/styles/mediaQuery';

import languages from '@config/languages';
import Checkbox from '@common/components/Checkbox';
import Overlay from '@common/components/Overlay';
import IconButton from '@common/components/IconButton';
import { MandatoryField } from '@modules/event/transforms/mandatoryField';
import { getTokens } from '@modules/auth/hooks/useAuth';
import Alert from '@common/components/Alert';
import { Beams, useBeams } from '@common/hooks/useBeams';

type EditProfileProps = {
  user?: UserResource;
  onSubmit: (values: UserResource) => void;
  onUploadAvatar: (file?: File) => void;
  openModal?: boolean;
  closeModal?: () => void;
};

const EditProfile: React.FC<EditProfileProps> = ({
  user,
  onSubmit,
  onUploadAvatar,
  openModal,
  closeModal,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const router = useRouter();
  const event = useEvent();
  const logout = useLogout();
  const { data: customFields, isLoading } = useCustomFields();
  const { initialized } = useBeams();
  const { data: tags } = useTags();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [canUsePush, setCanUsePush] = useState<boolean>(false);
  const [pushEnabled, setPushEnabled] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<TagResource[]>([]);
  const [attendance, setAttendance] = useState<UserResource['attendance'] | undefined>(
    user?.attendance,
  );
  const defaultFieldOrder: MandatoryField[] = [
    { id: '0', key: 'firstname' },
    { id: '1', key: 'lastname' },
    { id: '2', key: 'email' },
    { id: '3', key: 'language' },
    { id: '4', key: 'attendance' },
  ];
  const keyToName: { [key: string]: string } = { firstname: 'firstName', lastname: 'lastName' };
  const ASMLServer = process.env.NEXT_PUBLIC_ASML_SERVER === 'true';
  useEffect(
    () =>
      setSelectedTags((current) => {
        if (current.length === 0) {
          return user?.tags || [];
        }

        return current;
      }),
    [setSelectedTags, user],
  );

  useEffect(() => {
    Beams().then((client) => {
      setCanUsePush(client.hasNotificationSupport());
    });
  }, []);

  useEffect(() => {
    if (!initialized) return;
    Beams().then(async (client) => {
      setPushEnabled(await client.areNotificationsEnabled());
    });
  }, [initialized]);

  const handleNotificationChange = useCallback(
    async (data: any, actions: FormikHelpers<any>) => {
      if (pushEnabled !== data.notificationsEnabled) {
        const beamsClient = await Beams();
        if (!(await beamsClient.hasBrowserPermission())) {
          actions.setSubmitting(false);
          actions.setFieldError('notificationsEnabled', 'vle_error_notifications_disabled');
          return false;
        }
        if (data.notificationsEnabled) {
          const tokens = getTokens();
          if (tokens?.accessToken?.length && user?.id) {
            await beamsClient.unregister();
            await beamsClient.setUserId(tokens.accessToken, user.id);
          }
          if (event?.id?.length) {
            await beamsClient.setEventId(event.id);
          }
        } else {
          await beamsClient.unregister();
        }
        setPushEnabled(data.notificationsEnabled);
      }
      return true;
    },
    [event, pushEnabled, user],
  );

  const filteredLanguages = event?.languages
    ? languages.filter((item) => event?.languages?.includes(item.code))
    : [];

  const eventLanguages = filteredLanguages.map((language) => ({
    value: language.code,
    label: language.nativeName,
  }));

  const toggleTag = useCallback(
    (tag: TagResource) =>
      setSelectedTags((current) => {
        if (current.find((filterTag) => filterTag.id === tag.id)) {
          return current.filter((filterTag) => filterTag.id !== tag.id);
        }

        return [...current, tag];
      }),
    [setSelectedTags],
  );

  const onCloseModal = useCallback(() => {
    closeModal?.();
    setSelectedTags(user!.tags);
    setAttendance(user?.attendance);
  }, [user, closeModal]);

  if (!user) return null;
  if (isLoading) return null;

  return (
    <Overlay openModal={openModal} closeModal={onCloseModal}>
      <div
        css={css`
          cursor: default;
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: stretch;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          position: relative;
          pointer-events: all;
          -webkit-overflow-scrolling: auto;
          background-color: ${theme.colors.eventBackground}88;
          padding: 1em;
          box-sizing: border-box;
          backdrop-filter: blur(${theme.blurs.normal});
          @supports not (
            (backdrop-filter: blur(${theme.blurs.normal})) or
              (-webkit-backdrop-filter: blur(${theme.blurs.normal}))
          ) {
            background-color: ${theme.colors.eventBackground};
            border-color: #feddc6;
            border-width: 3px;
            border-style: solid;
          }
          @media only screen and (max-width: 768px) {
            padding: 14px 24px 8px 24px;
          }
          ${mediaQuery(
            's',
            css`
              border-radius: ${theme.borderRadius.normal};
              width: 90vw;
              max-width: 620px;
              height: 100vh;
              min-height: auto;
              padding: 2em;
              padding-bottom: calc(2em + 4px);
              max-height: 80vh;
              height: auto;
            `,
          )}
        `}
      >
        <IconButton
          css={css`
            position: absolute;
            top: 0.5em;
            right: 1em;
            padding: 0;

            ${mediaQuery(
              's',
              css`
                top: 1.5em;
                right: 2em;
              `,
            )}
          `}
          icon='times'
          size={40}
          type='transparent'
          onClick={onCloseModal}
        />
        <Heading size='h2' color='textLight'>
          {t('edit_profile')}
        </Heading>
        <Formik
          enableReinitialize
          initialValues={{
            ...user,
            attendance,
            fields:
              customFields?.reduce(
                (result, customField) => ({
                  ...result,
                  [customField.id]:
                    user.fields?.find((field) => field.customFieldId === customField.id)?.value ||
                    '',
                }),
                {},
              ) || {},
            notificationsEnabled: pushEnabled,
            subscribed: !user.unsubscribed,
            doNotDisturb: user.callStatus !== CallStatus.DO_NOT_DISTURB,
          }}
          onSubmit={async (data: any, actions) => {
            setSubmitting(true);
            if (!(await handleNotificationChange(data, actions))) {
              setSubmitting(false);
              return;
            }
            setSubmitting(false);
            onSubmit({
              ...data,
              attendance,
              tags: selectedTags.map((tag) => tag.id),
              fields: Object.keys(data.fields || {})
                ?.map((id) => ({
                  // eslint-disable-next-line security/detect-object-injection
                  value: data.fields[id],
                  customFieldId: id,
                }))
                .filter((field) => field.value) as UserResource['fields'],
              unsubscribed: !data.subscribed,
              callStatus: !data.doNotDisturb ? CallStatus.DO_NOT_DISTURB : CallStatus.AVAILABLE,
            });
          }}
        >
          {({ touched, errors }) => (
            <Form>
              {event?.avatarUploadEnabled && (
                <>
                  <AvatarUploadCard user={user} onDrop={onUploadAvatar} />
                  <div
                    css={css`
                      margin-top: 30px;
                    `}
                  />
                </>
              )}

              {(event?.mandatoryFieldOrder || defaultFieldOrder).map((field: MandatoryField) => {
                if (
                  field.key === 'email' ||
                  (field.key === 'attendance' && !event?.attendanceEnabled)
                )
                  return <></>;
                if (
                  (field.key === 'language' && !!filteredLanguages.length === false) ||
                  filteredLanguages.length === 1
                ) {
                  return <></>;
                }

                const inputName = keyToName[field.key] ?? field.key;

                if (field.key === 'language') {
                  return (
                    <Labeled key={field.id} label={t('language')}>
                      <Select name='language' options={eventLanguages} />
                    </Labeled>
                  );
                }
                if (field.key === 'attendance') {
                  return <></>;
                }

                return (
                  <Labeled key={field.id} label={`${t(field.key)} *`}>
                    <Input name={inputName} placeholder={t(`${field.key}_placeholder`)} />
                  </Labeled>
                );
              })}

              {customFields?.map((customField) => (
                <Labeled key={customField.id} label={customField.label}>
                  {customField.type === CustomFieldType.DROPDOWN ? (
                    <Select
                      name={`fields.${customField.id}`}
                      options={customField.options.map((v) => {
                        return { value: v.key, label: v.label };
                      })}
                    />
                  ) : (
                    <Input name={`fields.${customField.id}`} placeholder={customField.label} />
                  )}
                </Labeled>
              ))}

              {event?.attendanceEnabled && (
                <Labeled label={`${t('attendance')} *`}>
                  <Select
                    name='attendance'
                    value={attendance}
                    placeholder={`${t('attendance')}`}
                    onChange={(change: ChangeEvent<HTMLSelectElement>) =>
                      setAttendance(change.target.value as AttendanceType)
                    }
                    options={[
                      {
                        value: 'ONLINE',
                        label: t('attendance_location.online'),
                      },
                      {
                        value: 'ONSITE',
                        label: t('attendance_location.onsite'),
                      },
                    ]}
                  />
                </Labeled>
              )}

              <div
                css={css`
                  width: 100%;
                  height: 0;
                  opacity: 0.1;
                  border: solid 1px ${theme.colors.textLight};
                `}
              />
              {tags && tags.length !== 0 && (
                <>
                  <div
                    css={css`
                      margin: 1em 0;
                      ${mediaQuery(
                        's',
                        css`
                          margin: 2em 0;
                        `,
                      )}
                    `}
                  >
                    <Heading size='h2' color='textLight'>
                      {t('own_tags_title')}
                    </Heading>
                    <div
                      css={css`
                        margin-bottom: 15px;
                      `}
                    />
                    <TagCloud left tags={tags} selected={selectedTags} onTagClick={toggleTag} />
                  </div>
                  <div
                    css={css`
                      width: 100%;
                      height: 0;
                      opacity: 0.1;
                      border: solid 1px ${theme.colors.textLight};
                    `}
                  />
                </>
              )}

              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                `}
              >
                {!ASMLServer && (
                  <>
                    {event?.videoCallEnabled && (
                      <Checkbox name='doNotDisturb'>{t('accept-calls')}</Checkbox>
                    )}
                    <Checkbox name='subscribed'>{t('subscribed')}</Checkbox>
                  </>
                )}
              </div>

              {canUsePush && initialized && (
                <div
                  css={css`
                    margin-bottom: 2em;
                  `}
                >
                  {!!errors.notificationsEnabled && touched.notificationsEnabled && (
                    <div
                      css={css`
                        margin: 0.5em 0;
                        padding-bottom: 3rem;
                      `}
                    >
                      <Alert>{t(`${errors.notificationsEnabled}`)}</Alert>
                    </div>
                  )}
                  <Checkbox name='notificationsEnabled'>{t('notifications_enabled')}</Checkbox>
                </div>
              )}

              <div
                css={css`
                  width: 100%;
                  z-index: 2;
                  position: sticky;
                  bottom: 0;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;

                  :before {
                    content: '';
                    width: calc(100% + 4em);
                    height: 125px;
                    position: absolute;
                    top: -50px;
                    left: -2em;
                    z-index: -1;
                    background: linear-gradient(
                      180deg,
                      ${theme.colors.eventBackground}00,
                      ${theme.colors.eventBackground} 75%
                    );
                  }

                  ${mediaQuery(
                    's',
                    css`
                      margin-top: 2em;
                    `,
                  )}
                `}
              >
                <Button
                  type='submit'
                  label={t('save_button')}
                  size='m'
                  loading={submitting}
                  css={css`
                    margin-right: 0.5em;
                    flex: 1;
                    ${mediaQuery(
                      's',
                      css`
                        flex: 0;
                      `,
                    )}
                  `}
                />
                <Button
                  label={t('logout_button')}
                  onClick={() => {
                    logout();
                    router.push('/landing');
                  }}
                  css={css`
                    margin-left: 0.5em;
                    flex: 1;
                    ${mediaQuery(
                      's',
                      css`
                        flex: 0;
                      `,
                    )}
                  `}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Overlay>
  );
};

export default EditProfile;
