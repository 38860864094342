import { Icon } from 'app/types/icons';

const Moon: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.0842 15.2258C12.5475 14.6524 8.52831 9.74134 9.10716 4.25668C9.13778 3.96652 9.18066 3.68058 9.23523 3.39929C9.40256 2.53677 8.62678 1.70555 7.83538 2.10157C4.72223 3.65942 2.44798 6.70407 2.05837 10.3958C1.44905 16.1691 5.67975 21.3386 11.5079 21.9422C15.8605 22.393 19.8732 20.1696 21.8798 16.6101C22.312 15.8433 21.5054 15.042 20.6286 15.1717C19.8044 15.2937 18.9514 15.3156 18.0842 15.2258Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Moon;
