import React from 'react';

import { useRouter } from 'next/router';

import { useAppDispatch } from '@common/store';
import { setFullscreen } from '@common/store/sidebar';
import PushToPageCard from '@common/components/PushToPageCard';
import { useJoinVideoCall } from '@modules/video-call/hooks/useInitVideoCall';
import type { PushToPageResource } from '@common/store/pusher';

type PushToPageModalProps = {
  pushToPage: PushToPageResource;
  onClick: () => void;
  redirected?: boolean;
};

const PushToPageModal: React.FC<PushToPageModalProps> = ({ pushToPage, onClick, redirected }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const joinVideoCall = useJoinVideoCall();

  return (
    <PushToPageCard
      pushToPage={pushToPage}
      redirected={redirected}
      onClick={() => {
        onClick();
        if (!redirected) {
          if (pushToPage.isVideoCall) {
            joinVideoCall(pushToPage.id);
          } else {
            router.push(`/pages/${pushToPage.slug}`);
            dispatch(setFullscreen(false));
          }
        }
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        else window.open(pushToPage.slug);
      }}
    />
  );
};

export default PushToPageModal;
