import { SessionLocation, SessionResource } from '@nextempire/vle-common/lib/transforms/session';
import { NextRouter } from 'next/router';
import dayjs from 'dayjs';

import { Event } from '@modules/event/transforms/event';

export const removeQueryParamsFromRouter = (router: NextRouter, removeList: string[] = []) => {
  if (removeList.length > 0) {
    // eslint-disable-next-line security/detect-object-injection, no-param-reassign
    removeList.forEach((param) => delete router.query[param]);
  } else {
    // eslint-disable-next-line security/detect-object-injection, no-param-reassign
    Object.keys(router.query).forEach((param) => delete router.query[param]);
  }
  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    { shallow: true },
  );
};

export const isEventActive = (event?: Event) => {
  if (!event) {
    return false;
  }

  const currentDate = new Date();
  return (
    event.active &&
    dayjs(event.activeFrom).isBefore(currentDate) &&
    dayjs(event.activeTill).isAfter(currentDate)
  );
};

export const filterSessionsByLocation = (
  selectedLocation: SessionLocation,
  sessions: SessionResource[] | undefined,
) => {
  if (sessions) {
    switch (selectedLocation) {
      case SessionLocation.ONLINE:
        return sessions.filter(
          (session) =>
            session.location === SessionLocation.ONLINE ||
            session.location === SessionLocation.BOTH,
        );
      case SessionLocation.ONSITE:
        return sessions.filter(
          (session) =>
            session.location === SessionLocation.ONSITE ||
            session.location === SessionLocation.BOTH,
        );
      case SessionLocation.BOTH:
        return sessions;
      default:
        return sessions;
    }
  }

  return undefined;
};

export const renderLocationIcon = (selectedLocation: 'ONLINE' | 'ONSITE' | 'BOTH') => {
  if (selectedLocation) {
    switch (selectedLocation) {
      case 'ONLINE':
        return 'laptop';
      case 'ONSITE':
        return 'pinpoint';
      case 'BOTH':
        return 'both';
      default:
        return 'pinpoint';
    }
  }
  return 'pinpoint';
};
