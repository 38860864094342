import { Icon } from 'app/types/icons';

const Send: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.4262 3.02531L2.27497 9.93991C2.19159 9.97189 2.12022 10.0291 2.07096 10.1036C2.0217 10.1781 1.99706 10.2661 2.00028 10.3554C2.0035 10.4446 2.03449 10.5306 2.08899 10.6014C2.14349 10.6721 2.21877 10.7241 2.30424 10.75L9.70415 12.9695L13.4313 10.2748C13.4721 10.2605 13.5161 10.258 13.5582 10.2677C13.6003 10.2773 13.6389 10.2986 13.6694 10.3291C13.7 10.3597 13.7212 10.3982 13.7308 10.4403C13.7404 10.4824 13.7379 10.5264 13.7237 10.5672L11.0289 14.2942L13.25 21.6943C13.2759 21.7798 13.3277 21.855 13.3985 21.9095C13.4692 21.964 13.5554 21.995 13.6446 21.9983C13.7339 22.0015 13.8218 21.9767 13.8963 21.9275C13.9708 21.8782 14.028 21.8069 14.06 21.7235L20.9746 3.57362C21.0021 3.49712 21.0075 3.41434 20.9897 3.33496C20.972 3.25559 20.9321 3.1829 20.8746 3.1254C20.8171 3.06789 20.7443 3.02795 20.6649 3.01023C20.5855 2.9925 20.5028 2.99773 20.4262 3.02531V3.02531Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Send;
