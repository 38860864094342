import { EventResource } from '@modules/event/transforms/event';
import theme from '@common/styles/theme';

const eventTheme = (event?: EventResource) => {
  const safeColor = (hex: string) => {
    return (hex && hex.length === 7 && hex[0] === '#' && hex) || '#000000';
  };

  return {
    ...theme,
    colors: {
      ...theme.colors,
      eventBackground: safeColor(event?.themeBackgroundColor || theme.colors.eventBackground),
      primary: safeColor(event?.themePrimaryColor || theme.colors.primary),
      secondary: safeColor(event?.themeSecondaryColor || theme.colors.secondary),
    },
  };
};

export default eventTheme;
