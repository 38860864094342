import { DIRECTION } from '@common/utils/consts';
import { Icon } from 'app/types/icons';

const Arrow: React.FC<Icon> = ({ size, color, direction }) => {
  const getArrowPath = () => {
    switch (direction) {
      case DIRECTION.UP:
        return (
          <path
            d='M19.8028 10.781C19.4751 11.1087 18.9594 11.1339 18.6028 10.8566L18.5172 10.781L12.91 5.17455L12.9091 21.16C12.9091 21.6621 12.5021 22.0691 12 22.0691C11.5338 22.0691 11.1495 21.7181 11.097 21.266L11.0909 21.16L11.0909 5.17364L5.48282 10.781C5.15511 11.1087 4.63945 11.1339 4.28282 10.8566L4.19718 10.781C3.86946 10.4533 3.84425 9.93763 4.12155 9.581L4.19718 9.49536L11.3572 2.33536C11.6849 2.00765 12.2006 1.98244 12.5572 2.25973L12.6428 2.33536L19.8028 9.49536C20.1578 9.85038 20.1578 10.426 19.8028 10.781Z'
            fill={color}
            strokeWidth={0}
          />
        );
      case DIRECTION.RIGHT:
        return (
          <path
            d='M13.2881 19.8719C12.9604 19.5442 12.9352 19.0285 13.2125 18.6719L13.2881 18.5863L18.8945 12.9791L2.90909 12.9782C2.40701 12.9782 2 12.5712 2 12.0691C2 11.6029 2.35095 11.2186 2.80307 11.1661L2.90909 11.16L18.8955 11.16L13.2881 5.55191C12.9604 5.2242 12.9352 4.70854 13.2125 4.35191L13.2881 4.26627C13.6158 3.93855 14.1315 3.91334 14.4881 4.19064L14.5737 4.26627L21.7337 11.4263C22.0614 11.754 22.0867 12.2696 21.8094 12.6263L21.7337 12.7119L14.5737 19.8719C14.2187 20.2269 13.6431 20.2269 13.2881 19.8719Z'
            fill={color}
            strokeWidth={0}
          />
        );
      case DIRECTION.DOWN:
        return (
          <path
            d='M4.19717 13.3572C4.52489 13.0295 5.04055 13.0043 5.39718 13.2815L5.48282 13.3572L11.09 18.9636L11.0909 2.97818C11.0909 2.4761 11.4979 2.06909 12 2.06909C12.4662 2.06909 12.8505 2.42004 12.903 2.87216L12.9091 2.97818L12.9091 18.9645L18.5172 13.3572C18.8449 13.0295 19.3606 13.0043 19.7172 13.2815L19.8028 13.3572C20.1305 13.6849 20.1557 14.2006 19.8785 14.5572L19.8028 14.6428L12.6428 21.8028C12.3151 22.1305 11.7994 22.1557 11.4428 21.8784L11.3572 21.8028L4.19717 14.6428C3.84215 14.2878 3.84215 13.7122 4.19717 13.3572Z'
            fill={color}
            strokeWidth={0}
          />
        );
      default:
        return (
          <path
            d='M10.7119 4.26627C11.0396 4.59398 11.0648 5.10964 10.7875 5.46627L10.7119 5.55192L5.10545 11.1591L21.0909 11.16C21.593 11.16 22 11.567 22 12.0691C22 12.5353 21.6491 12.9196 21.1969 12.9721L21.0909 12.9782H5.10455L10.7119 18.5863C11.0396 18.914 11.0648 19.4296 10.7875 19.7863L10.7119 19.8719C10.3842 20.1996 9.86854 20.2248 9.51191 19.9475L9.42627 19.8719L2.26627 12.7119C1.93855 12.3842 1.91335 11.8685 2.19064 11.5119L2.26627 11.4263L9.42627 4.26627C9.78129 3.91124 10.3569 3.91124 10.7119 4.26627Z'
            fill={color}
            strokeWidth={0}
          />
        );
    }
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {getArrowPath()}
    </svg>
  );
};

export default Arrow;
