/* eslint-disable security/detect-object-injection */
import React, { forwardRef } from 'react';
import { css, useTheme } from '@emotion/react';
import { PulseLoader } from 'react-spinners';
import { readableColor } from 'polished';

import Icon from '@common/components/Icon';
import { mediaQuery } from '@common/styles/mediaQuery';
import { Icon as IconProps } from 'app/types/icons';

type ButtonProps = {
  className?: string;
  color?: 'primary' | 'secondary';
  size?: 'xs' | 's' | 'm' | 'l';
  label?: React.ReactNode;
  outline?: boolean;
  plain?: boolean;
  iconSize?: number;
  iconPlacement?: 'left' | 'right';
  icon?: string | React.ReactNode;
  iconProps?: Partial<IconProps>;
  onClick?: () => void;
  disabled?: boolean;
  disableMinWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      color = 'primary',
      size = 'm',
      iconPlacement = 'left',
      label,
      outline,
      plain,
      icon: IIcon,
      iconSize,
      iconProps,
      onClick,
      disabled,
      disableMinWidth,
      type = 'button',
      loading = false,
      ...restProps
    },
    ref,
  ) => {
    const theme = useTheme();
    const nativeOnClick = (ev: any) => {
      onClick?.();
      ev.stopPropagation();
    };

    const variant = disabled ? 'disabled' : color;

    const getBackgroundColor = () => {
      if (plain || outline) {
        return 'transparent';
      }

      return theme.colors[variant];
    };

    const getBorderColor = () => {
      if (plain || !outline) {
        return 'transparent';
      }

      return readableColor(theme.colors.eventBackground);
    };

    const getTextColor = (hover?: boolean) => {
      if (plain || outline) {
        if (hover) return theme.colors.text;
        return readableColor(theme.colors.eventBackground);
      }

      if (disabled) {
        return theme.colors.disabledText;
      }

      return readableColor(getBackgroundColor());
    };

    return (
      <button
        className={className}
        ref={ref}
        css={css`
          display: flex;
          position: relative;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: ${theme.paddings[size]};
          background-color: ${getBackgroundColor()};
          border: 1px solid ${getBorderColor()};
          color: ${getTextColor()};
          border-radius: 100px;
          transition: 0.3s ease-in-out all;
          cursor: ${disabled ? 'default' : 'pointer'};
          font-weight: ${theme.fontWeights.medium};
          font-size: ${theme.fontSizes.s};
          line-height: 1.5em;
          vertical-align: middle;
          &:focus {
            outline: none;
          }

          ${!disabled &&
          `
            &:hover {
              filter: brightness(90%);
              background-color: ${theme.colors[variant]};
              color: ${getTextColor(true)};
            }
          `}
          ${mediaQuery(
            's',
            css`
              min-width: ${disableMinWidth ? 'none' : '200px'};
            `,
          )}
        `}
        onClick={nativeOnClick}
        type={type}
        {...restProps}
      >
        <div
          css={css`
            opacity: ${loading ? 0 : 1};
            display: flex;
            flex-direction: row;
          `}
        >
          {iconPlacement === 'left' && IIcon && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 0.4em;
              `}
            >
              {typeof IIcon === 'string' ? (
                <Icon icon={IIcon} size={iconSize ?? 12} color={getTextColor()} {...iconProps} />
              ) : (
                IIcon
              )}
            </div>
          )}
          {label}
          {iconPlacement === 'right' && IIcon && (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 0.4em;
              `}
            >
              {typeof IIcon === 'string' ? (
                <Icon icon={IIcon} size={iconSize ?? 12} color={getTextColor()} />
              ) : (
                IIcon
              )}
            </div>
          )}
        </div>
        {loading && (
          <div
            css={css`
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              display: flex;
              padding-top: 5px;
              align-items: center;
              justify-content: center;
            `}
          >
            <PulseLoader size={10} color={theme.colors.background} />
          </div>
        )}
      </button>
    );
  },
);

export default Button;
