import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Avatar from '@modules/user/components/Avatar';
import Body from '@common/components/Body';
import Card from '@common/components/Card';

import { User } from '@modules/user/transforms/user';
import { Platform, usePlatform } from '@common/hooks/usePlatform';
import { mediaQuery } from '@common/styles/mediaQuery';

type AvatarUploadCardProps = {
  user?: Partial<User>;
  onDrop: (file?: File) => void;
};

const AvatarUploadCard: React.FC<AvatarUploadCardProps> = ({ user, onDrop }) => {
  const platform = usePlatform();
  const onDropCallback = useCallback((acceptedFiles: File[]) => onDrop(acceptedFiles[0]), [onDrop]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: onDropCallback,
  });
  const { t } = useTranslation();

  return (
    <div
      {...getRootProps()}
      css={css`
        cursor: pointer;
        margin: 30px 0;

        &:focus {
          outline: none;
        }
      `}
    >
      <input {...getInputProps()} />
      <Card
        opacity={isDragActive ? 0.15 : 0.1}
        css={css`
          flex-direction: row;
          padding: 1em;
        `}
      >
        {user && (
          <Avatar user={user} size={platform === Platform.MOBILE ? 56 : 88} emptyBackground />
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 2em;
          `}
        >
          <Body weight='bold' color='textLight'>
            {t('upload_avatar_title')}
          </Body>
          <Body
            color='textLight'
            css={css`
              margin-top: 0.5em;
              ${mediaQuery(
                's',
                css`
                  margin-top: 0.75em;
                `,
              )}
            `}
          >
            {t('upload_avatar_button_text')}
          </Body>
        </div>
      </Card>
    </div>
  );
};

export default AvatarUploadCard;
