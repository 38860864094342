import parse, { Attributes } from '@common/utils/parse';
import { parseUser } from '@modules/user/transforms/user';

export const parseMessage = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    createdAt: attributes.date('createdAt'),
    message: attributes.string('message'),
    userId: attributes.string('userId'),
    participant: attributes.optional.custom('user', (user: any) => {
      return parse.one(user, parseUser);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseMessage);
}

export function many(data: any) {
  return parse.many(data, parseMessage);
}

export type MessageResource = ReturnType<typeof one>;
export type Message = Omit<MessageResource, 'id'>;

export default { one, many };
