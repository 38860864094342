import { Icon } from 'app/types/icons';

const DeclineCall: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.4028 12.9646C16.8814 13.2116 16.4757 14.0445 16.4434 15.2227C16.3993 16.7989 16.5508 16.8616 18.7168 16.8628C19.8469 16.8616 21.5904 16.9685 22.5284 16.9316C23.7408 16.8849 23.89 16.6773 23.9759 15.4144C24.0531 14.5987 23.944 13.7757 23.6572 13.0112C23.3705 12.2467 22.914 11.5618 22.3243 11.0113C18.7526 7.24333 8.4694 7.33792 3.77115 9.50139C2.98099 9.83931 2.26717 10.3416 1.67441 10.9769C1.08521 11.5275 0.629142 12.2122 0.342594 12.9765C0.0560462 13.7407 -0.0530539 14.5634 0.0239974 15.3787C0.103952 16.6478 0.262669 16.853 1.47274 16.8972C2.41072 16.9341 4.15541 16.8997 5.28313 16.8272C7.4276 16.6883 7.60422 16.7596 7.55648 15.1871C7.52307 14.0101 7.12091 13.1784 8.59829 12.9302C10.8638 12.7584 13.139 12.7699 15.4028 12.9646Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default DeclineCall;
