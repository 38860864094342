import { css, useTheme } from '@emotion/react';
import { transparentize } from 'polished';

type BlurProps = {
  appearance?: 'light' | 'dark';
};

const Blur: React.FC<BlurProps> = ({ appearance = 'light' }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        box-sizing: border-box;
        backdrop-filter: blur(${theme.blurs.normal});
        background-color: ${transparentize(
          appearance === 'light' ? 0.4 : 0.2,
          theme.colors.eventBackground,
        )};
      `}
    />
  );
};

export default Blur;
