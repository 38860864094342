import { Icon } from 'app/types/icons';

const Pinpoint: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0' fill='none' width='24' height='24' />
      <g>
        <path
          stroke={color}
          fill={color}
          d='M19 9c0-3.866-3.134-7-7-7S5 5.134 5 9c0 1.387.41 2.677 1.105 3.765h-.008C8.457 16.46 12 22 12 22l5.903-9.235h-.007C18.59 11.677 19 10.387 19 9zm-7 3c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z'
        />
      </g>
    </svg>
  );
};

export default Pinpoint;
