import { Icon } from 'app/types/icons';

const Minus: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='2' y='10.5' width='20' height='3' rx='1.5' fill={color} strokeWidth={0} />
    </svg>
  );
};

export default Minus;
