import { useEffect, useRef, useMemo } from 'react';
import { NextPageContext } from 'next';
import Head from 'next/head';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/node';
import { css, ThemeProvider } from '@emotion/react';
import { useRouter } from 'next/router';
import { gsap } from 'gsap';

import Heading from '@common/components/Heading';
import Body from '@common/components/Body';
import Header from '@common/components/Header';
import Menu from '@common/components/Menu';
import GlobalTheme from '@common/components/GlobalTheme';
import Button from '@common/components/Button';
import { useEvent } from '@modules/event/hooks/useEvent';
import { useMenu } from '@common/hooks/useMenu';
import eventTheme from '@common/styles/eventTheme';
import theme from '@common/styles/theme';
import { store, useAppSelector } from '@common/store';
import EventBackground from '@modules/event/components/EventBackground';
import { EventResource } from '@modules/event/transforms/event';
import { isEventActive } from '@modules/event/utils';

import Error500 from './500';

export interface ErrorPageProps {
  statusCode?: number;
  isReported?: boolean;
  err: any;
}

function ErrorHeader({ event }: { event: EventResource }) {
  const router = useRouter();
  const { livePages, otherPages } = useMenu();
  const menuRef = useRef<HTMLDivElement>(null);
  const showMenu = useAppSelector((state) => state.menu.showMenu);

  const menuTimeline = useMemo(
    () =>
      menuRef.current &&
      menuRef.current.firstChild &&
      gsap
        .timeline({
          paused: true,
        })

        .fromTo(
          menuRef.current,
          { left: '-100%', top: '-100%', borderBottomRightRadius: '300px' },
          {
            top: '65px',
            left: 0,
            borderBottomRightRadius: '300px',
            duration: 0.7,
            ease: 'circ.out',
          },
          'start',
        )
        .fromTo(
          menuRef.current,
          { borderBottomRightRadius: '300px' },
          {
            borderBottomRightRadius: '0px',
            duration: 0.5,
            ease: 'circ.out',
          },
          'start+=0.5',
        )
        .fromTo(
          menuRef.current.children[0]?.childNodes,
          { opacity: 0, y: -45 },
          { opacity: 1, stagger: 0.2, y: 0, ease: 'power4.inOut' },
          'start+=0.7',
        )
        .fromTo(
          menuRef.current.children[1]?.childNodes,
          { opacity: 0, y: -45 },
          { opacity: 1, stagger: 0.2, y: 0, ease: 'power4.inOut' },
          '-=0.2',
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showMenu], // dependancy required to rerender when needed
  );

  return (
    <Header
      ref={menuRef}
      timeline={menuTimeline}
      title={event?.name}
      liveMenu={
        livePages &&
        livePages.length > 0 && (
          <Menu
            live
            items={livePages.map((page) => ({
              key: page.id,
              label: page.title,
              active: false,
            }))}
            onItemClick={(key) =>
              router.push(`/pages/${livePages?.find((page) => page.id === key)?.slug}`)
            }
          />
        )
      }
      mainMenu={
        otherPages &&
        otherPages.length > 0 && (
          <Menu
            items={otherPages.map((page) => ({
              key: page.id,
              label: page.title,
              active: false,
            }))}
            onItemClick={(key) =>
              router.push(`/pages/${otherPages?.find((page) => page.id === key)?.slug}`)
            }
          />
        )
      }
    />
  );
}

function ErrorPageContent({ isReported = false, err }: ErrorPageProps) {
  const event = useEvent();
  const router = useRouter();
  const isAuthorized = useAppSelector((state) => state?.auth?.isAuthorized);

  useEffect(() => {
    if (isReported || !err) return;
    Sentry.captureException(err);
  }, [isReported, err]);

  return (
    <ThemeProvider theme={event ? eventTheme(event) : theme}>
      <GlobalTheme />
      <Head>
        <title>{event?.name}</title>
      </Head>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
        `}
      >
        {event && <ErrorHeader event={event} />}
        <EventBackground event={event} absolute appearance='dark' blur>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              padding: 2em;
              padding-top: 85px;
            `}
          >
            <div>
              <Heading
                size='h1'
                color='primary'
                css={css`
                  margin-bottom: 1rem;
                `}
              >
                Oops.
                <br />
                This {isEventActive(event) ? 'page' : 'event'} is not accessible to you right now.
              </Heading>
              {isEventActive(event) && (
                <Body color='textLight'>
                  Click the refresh button to try again. If the problem persists, please contact
                  your event host.
                </Body>
              )}
              {event && !isAuthorized && isEventActive(event) && (
                <Button
                  css={css`
                    margin-top: 2em;
                  `}
                  label='Login or register'
                  onClick={() => router.push('/landing')}
                />
              )}
            </div>
          </div>
        </EventBackground>
      </div>
    </ThemeProvider>
  );
}

function ErrorPage(props: ErrorPageProps) {
  if (
    /* eslint-disable react/destructuring-assignment */
    props.statusCode === 500 ||
    props.err?.response?.body?.message === 'vle_error_server' ||
    props.err?.response?.body?.error === 'vle_error_server'
    /* eslint-enable react/destructuring-assignment */
  )
    return <Error500 />;

  return (
    <Provider store={store}>
      <ErrorPageContent {...props} />
    </Provider>
  );
}

ErrorPage.getInitialProps = async ({ res, err, asPath }: NextPageContext) => {
  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  if (statusCode !== 404) {
    Sentry.captureException(err || new Error(`_error.ts rendered without an exception: ${asPath}`));
    await Sentry.flush(2000);
  }

  return { statusCode, isReported: true, authorize: false };
};

export default ErrorPage;
