import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import transform from '@modules/event/transforms/tag';
import { getStaleTime } from '@common/utils/staleTime';
import fetch from '@common/utils/fetch';
import { useAppSelector } from '@common/store';

export const useTag = (tagId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['tag', event?.id, tagId],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/tags/${tagId}?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transform.one(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export const useTags = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();

  return useQuery(
    ['tags', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/tags?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      ...getStaleTime(5),
    },
  );
};

export default { useTag, useTags };
