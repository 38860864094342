import { Icon } from 'app/types/icons';

const Mandatory: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.99998 2H14V8.5359L19.6602 5.26795L21.6602 8.73205L16 12L21.6603 15.2679L19.6603 18.732L14 15.4641V22H9.99998V15.4641L4.33972 18.732L2.33972 15.2679L7.99999 12L2.33975 8.73205L4.33975 5.26795L9.99998 8.53588V2Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Mandatory;
