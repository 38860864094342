import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { css, useTheme } from '@emotion/react';
import { readableColor } from 'polished';

import Avatar from '@modules/user/components/Avatar';
import EditProfile from '@modules/user/components/EditProfile';
import { HeaderProps } from '@common/components/Header';
import { useEvent } from '@modules/event/hooks/useEvent';

import { mediaQuery } from '@common/styles/mediaQuery';
import Heading from '@common/components/Heading';
import { useAppSelector } from '@common/store';
import { useUpdateUser } from '@modules/auth/hooks/useUpdateUser';

interface DesktopHeaderProps extends HeaderProps {
  openSchedule: () => void;
  hasScheduleOpen: boolean;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({ title, liveMenu, mainMenu }) => {
  const { updateUser, uploadAvatar } = useUpdateUser();
  const user = useAppSelector((state) => state.auth.user);
  const theme = useTheme();
  const event = useEvent();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const eventLogo = () => {
    return (
      <a
        href='/'
        css={css`
          text-decoration: none;
          color: ${readableColor(theme.colors.eventBackground)};
        `}
      >
        {event?.themeLogoUrl !== undefined && !event?.slug.includes('flow-launch') ? (
          <img
            src={event?.themeLogoUrl}
            alt=''
            css={css`
              height: 40pt;
            `}
          />
        ) : (
          <Heading size='subtitle' color='textLight'>
            {title}
          </Heading>
        )}
      </a>
    );
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 1;
        padding: 1rem;
        background: ${theme.colors.eventBackground};
        ${mediaQuery(
          'm',
          css`
            padding: 1rem 2rem;
          `,
        )}
      `}
    >
      {eventLogo()}
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <div
          css={css`
            flex: 1;
          `}
        />
        {liveMenu}
        <div
          css={css`
            width: 1em;
          `}
        />
        {mainMenu}
        <div
          css={css`
            width: 1em;
          `}
        />
      </div>

      {!!user && (
        <>
          <div onClick={() => setOpenModal(true)}>
            <Avatar
              user={user}
              size={40}
              showLocation={event?.attendanceEnabled}
              css={css`
                cursor: pointer;
              `}
            />
          </div>
        </>
      )}
      {ReactDOM.createPortal(
        <EditProfile
          user={user}
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          onSubmit={(data) => {
            updateUser(data);
            setOpenModal(false);
          }}
          onUploadAvatar={(file) => file && uploadAvatar(file)}
        />,
        document.querySelector('#popupHolder') ?? document.body,
      )}
    </div>
  );
};

export default DesktopHeader;
