import { useEffect } from 'react';

import useLanguage from '@common/hooks/useLanguage';
import { useAppDispatch, useAppSelector } from '@common/store';

import { useEvent } from '@modules/event/hooks/useEvent';
import { useRefresh } from '@modules/auth/hooks/useRefresh';
import { setInitializing } from '@modules/auth/store/auth';
import { TokenResource } from '@modules/auth/transforms/token';
import { readRefreshToken, readAccessToken, writeTokens } from '@modules/auth/utils';

let userId: string | undefined;

export const getUserId = () => {
  return userId;
};

export type Tokens = Pick<Partial<TokenResource>, 'accessToken' | 'refreshToken'>;
export const tokens: Tokens = {
  accessToken: readAccessToken(),
  refreshToken: readRefreshToken(),
};

export function getTokens() {
  return { ...tokens };
}
export function getAccessToken() {
  return tokens.accessToken;
}

export function setTokens(data: Partial<TokenResource>) {
  tokens.refreshToken = data.refreshToken;
  tokens.accessToken = data.accessToken;
  writeTokens(data);
  return tokens;
}

const useInitAuth = () => {
  const event = useEvent();
  const { user } = useAppSelector((state) => state.auth);

  const refresh = useRefresh();
  const dispatch = useAppDispatch();
  const { updateLanguage } = useLanguage();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    refresh().finally(() => {
      dispatch(setInitializing(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (user?.language && event?.languages?.includes(user?.language)) {
      updateLanguage(user?.language);
    } else if (event?.languages instanceof Array && event?.languages?.length > 0) {
      if (event.languages.includes('en')) {
        updateLanguage('en');
      } else {
        updateLanguage(event.languages[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.language]);

  useEffect(() => {
    userId = user?.id;
  }, [user]);
};

export const AuthInitializer: React.FC = ({ children }) => {
  useInitAuth();
  const isInitializing = useAppSelector((state) => state.auth.isInitializing);
  if (isInitializing) return null;
  return <>{children}</>;
};
