import parse, { Attributes } from '@common/utils/parse';

function transformStringArray(data: any): string[] {
  if (!Array.isArray(data)) return [];
  return data.filter((value: any) => typeof value === 'string') as string[];
}

export const parseEvent = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    slug: attributes.string('slug'),
    name: attributes.string('name'),
    active: attributes.boolean('active'),
    activeFrom: attributes.optional.date('activeFrom'),
    activeTill: attributes.optional.date('activeTill'),
    attendanceEnabled: attributes.optional.boolean('attendanceEnabled'),
    tagline: attributes.optional.string('tagline'),
    description: attributes.optional.string('description'),
    venueUrl: attributes.optional.string('venueUrl'),
    privacyPolicyUrl: attributes.optional.string('privacyPolicyUrl'),
    maxUsers: attributes.optional.number('maxUsers'),
    defaultLanguage: attributes.string('defaultLanguage'),
    languages: attributes.optional.custom('languages', transformStringArray),
    organiser: attributes.optional.string('organiser'),
    organiserContact: attributes.optional.string('organiserContact'),
    googleTrackingId: attributes.optional.string('googleTrackingId'),
    chatEnabled: attributes.boolean('chatEnabled'),
    avatarUploadEnabled: attributes.boolean('avatarUploadEnabled'),
    attendeesInSidebarEnabled: attributes.boolean('attendeesInSidebarEnabled'),
    privateChatEnabled: attributes.boolean('privateChatEnabled'),
    hostChatEnabled: attributes.boolean('hostChatEnabled'),
    videoCallEnabled: attributes.boolean('videoCallEnabled'),
    rolesEnabled: attributes.boolean('rolesEnabled'),
    registrationEnabled: attributes.optional.boolean('registrationEnabled'),
    requiredCustomFields: attributes.optional.number('requiredCustomFields'),
    loginEnabled: attributes.optional.boolean('loginEnabled'),
    autoApproval: attributes.optional.boolean('autoApproval'),
    startsAt: attributes.date('startsAt'),
    endsAt: attributes.date('endsAt'),
    themeBackgroundUrl: attributes.optional.string('themeBackgroundUrl'),
    themeLogoUrl: attributes.optional.string('themeLogoUrl'),
    themeFaviconUrl: attributes.optional.string('themeFaviconUrl'),
    themeBackgroundColor: attributes.optional.string('themeBackgroundColor'),
    themePrimaryColor: attributes.optional.string('themePrimaryColor'),
    themeSecondaryColor: attributes.optional.string('themeSecondaryColor'),
    initialPageSlug: attributes.optional.string('initialPage.slug'),
    registrationCompletedMessage: attributes.optional.string('registrationCompletedMessage'),
    mandatoryFieldOrder: attributes.optional.custom('mandatoryFieldOrder', (data) => data),
    samlEnabled: attributes.optional.boolean('samlEnabled'),
  };
};

export function one(data: any) {
  return parse.one(data, parseEvent);
}

export function many(data: any) {
  return parse.many(data, parseEvent);
}

export type EventResource = ReturnType<typeof one>;
export type Event = Omit<EventResource, 'id'>;

export default { one, many };
