import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css, useTheme } from '@emotion/react';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import { SessionResource } from '@nextempire/vle-common/lib/transforms/session';
import ReactDOM from 'react-dom';

import Icon from '@common/components/Icon';
import { useCurrentTime } from '@common/hooks/useCurrentTime';
import Button from '@common/components/Button';
import Checkbox from '@common/components/Checkbox';
import { mediaQuery } from '@common/styles/mediaQuery';
import { convertDatesToTimeSlot } from '@common/utils/time';
import { stripHTML } from '@common/utils/html';
import IconButton from '@common/components/IconButton';
import { useSessionContext } from '@modules/schedule/hooks/useSessionContext';
import { useSessionSettings } from '@modules/schedule/hooks/useSessionSettings';
import { isExternalUrl } from '@common/utils/checkUrl';
import { usePages } from '@modules/event/hooks/usePages';
import {
  SESSION_STATES,
  getStatus,
  useSessionStatus,
} from '@modules/schedule/hooks/useSessionStatus';

import PushToPageModal from '../PushToPageModal';

type ReminderProps = {
  session: SessionResource;
  onClose: () => void;
};

const Reminder: React.FC<ReminderProps> = ({ session, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: pages } = usePages();
  const { title, description, imageUrl, mandatory } = session;
  const startsAt = dayjs(session.startsAt).toDate();
  const endsAt = dayjs(session.endsAt).toDate();
  const currentTime = useCurrentTime();
  const [autoJoin, setAutoJoin] = useState(mandatory);
  const [redirected, setRedirected] = useState(false);
  const { joinSession } = useSessionContext();
  const [sessionStatus, setSessionStatus] = useState<SESSION_STATES>(getStatus(session));
  const { buttons } = useSessionSettings(session, sessionStatus);

  useSessionStatus(session, setSessionStatus);

  const [mandatoryClosed, setMandatoryClosed] = useState(false);
  useEffect(() => {
    if (
      dayjs(currentTime).isAfter(dayjs(startsAt)) &&
      (mandatory || autoJoin) &&
      session.automaticPush
    ) {
      if (session.url && isExternalUrl(session.url)) {
        setRedirected(true);
      } else {
        joinSession(session.url);
        onClose?.();
      }
    } else if (dayjs(currentTime).isAfter(dayjs(session.endsAt)) && !mandatory && !autoJoin) {
      onClose?.();
    }
  }, [
    currentTime,
    startsAt,
    mandatory,
    autoJoin,
    joinSession,
    session.url,
    session.automaticPush,
    onClose,
    session.endsAt,
  ]);

  const handleSubmit = () => {
    onClose?.();
    joinSession(session.url);
  };

  const canJoin = useMemo(() => {
    if (!session.url?.startsWith('?page=')) return true;
    if (!pages) return false;
    return pages.some((page) => page.id === session.url?.replace?.('?page=', ''));
  }, [session.url, pages]);

  return (
    <>
      {!mandatoryClosed ? (
        <Formik initialValues={{ autoJoin }} onSubmit={handleSubmit}>
          <Form
            onChange={(e: any) => {
              setAutoJoin(e.target.value !== 'true');
            }}
          >
            <div
              css={css`
                float: left;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                background-color: ${theme.colors.alerts.background};
                backdrop-filter: blur(${theme.blurs.normal});
                border-radius: ${theme.borderRadius.iconButton};
                overflow: hidden;
                align-items: stretch;
              `}
            >
              <div
                css={css`
                  background: url('${imageUrl}') no-repeat center center;
                  background-size: cover;
                  width: 150px;
                  min-height: 130px;
                  padding: 10px;
                `}
              >
                <IconButton
                  icon='times'
                  size={40}
                  type='defaultDark'
                  onClick={() => setMandatoryClosed(true)}
                />
              </div>
              <div
                css={css`
                  padding: 20px;
                  min-height: 100%;
                  float: left;
                  flex: 1;
                  overflow: hidden;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column-reverse;

                    ${mediaQuery(
                      'm',
                      css`
                        flex-direction: row;
                        align-items: space-between;
                      `,
                    )}
                  `}
                >
                  <p
                    css={css`
                      margin: 0;
                      color: ${theme.colors.alerts.text};
                      font-size: ${theme.fontSizes.s};
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <Icon icon='clock' size={14} color={theme.colors.alerts.text} />
                    &nbsp;{convertDatesToTimeSlot(startsAt, endsAt)}
                  </p>
                </div>
                <div
                  css={css`
                    margin-top: 10px;
                    margin-bottom: 10px;
                  `}
                >
                  <p
                    css={css`
                      margin: 0;
                      color: ${theme.colors.alerts.text};
                      font-size: ${theme.fontSizes.subtitle};
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    `}
                  >
                    {title}
                  </p>
                  <p
                    css={css`
                      margin: 0;
                      margin-top: 4px;
                      color: ${theme.colors.alerts.text};
                      font-size: ${theme.fontSizes.s};
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    `}
                  >
                    {stripHTML(description)}
                  </p>
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;

                    ${mediaQuery(
                      'm',
                      css`
                        margin-top: 0;
                        flex-direction: row;
                        align-items: space-between;
                      `,
                    )}
                  `}
                >
                  {canJoin && session.automaticPush && (
                    <Checkbox
                      name='autoJoin'
                      color='secondary'
                      disabled={mandatory}
                      size={20}
                      css={css`
                        padding: 0;
                        flex: 1;
                        font-size: ${theme.fontSizes.s};

                        .checkboxContainer {
                          padding-top: 0.2em;
                          margin-top: 0.3em;
                        }
                      `}
                      round
                    >
                      {t('autoJoinBeforeSession')}
                    </Checkbox>
                  )}
                  {canJoin && buttons.showJoin && (
                    <Button
                      color='secondary'
                      disableMinWidth
                      label={t('gotoSession')}
                      type='submit'
                      css={css`
                        margin-top: 20px;

                        ${mediaQuery(
                          'm',
                          css`
                            margin-left: ${session.automaticPush ? '10px' : '0px'};
                            margin-top: 0;
                            flex-direction: row;
                            align-items: space-between;
                          `,
                        )}
                      `}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      ) : null}
      {redirected &&
        session.url &&
        ReactDOM.createPortal(
          <div
            css={css`
              position: fixed;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              z-index: 100000;
            `}
          >
            <PushToPageModal
              redirected={redirected}
              pushToPage={{
                id: '',
                title: t('go_to_session'),
                slug: session.url,
                isVideoCall: false,
              }}
              onClick={() => {
                setRedirected(false);
                onClose?.();
              }}
            />
          </div>,
          document.querySelector('#popupHolder') ?? document.body,
        )}
    </>
  );
};

export default Reminder;
