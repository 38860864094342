import { createContext, useContext } from 'react';

import { useJoinSession } from './useJoinSession';

export type SessionContextProps = {
  joinSession: (id: string | undefined | null) => void;
};

const sessionContext = createContext<SessionContextProps>({
  joinSession: () => undefined,
});

export const useSessionContext = () => {
  return useContext(sessionContext);
};

export const SessionContextProvider: React.FC = ({ children }) => {
  const sContext = useSessionContext();
  const { joinSession } = useJoinSession();

  return (
    <sessionContext.Provider
      value={{
        ...sContext,
        joinSession,
      }}
    >
      {children}
    </sessionContext.Provider>
  );
};

export default {
  useSessionContext,
  SessionContextProvider,
};
