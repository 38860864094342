import { css } from '@emotion/react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';

type OverlayProps = {
  openModal?: boolean;
  closeModal?: () => void;
};

const Overlay: React.FC<OverlayProps> = ({ children, openModal, closeModal }) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  return (
    <Transition
      in={openModal}
      unmountOnExit
      mountOnEnter
      addEndListener={(node, done) => {
        if (openModal) {
          gsap.fromTo(
            node,
            { opacity: 0, scale: 0.96 },
            {
              opacity: 1,
              duration: 0.3,
              scale: 1,
              ease: 'power4.inOut',
              onComplete: done,
            },
          );
        } else {
          gsap.fromTo(
            node,
            { opacity: 1, scale: 1 },
            {
              opacity: 0,
              duration: 0.4,
              scale: 0.96,
              ease: 'power4.inOut',
              onComplete: done,
            },
          );
        }
      }}
    >
      <div
        ref={overlayRef}
        aria-hidden='true'
        css={css`
          transform: scale(0.96), translateZ(1px);
          will-change: transform, opacity;
          background: rgba(0, 0, 0, 0.6);
          opacity: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 8000;
          backface-visibility: hidden;
          perspective: 1000;
        `}
        onClick={(e) => e.target === overlayRef.current && closeModal?.()}
      >
        {children}
      </div>
    </Transition>
  );
};

export default Overlay;
