import { ReactNode } from 'react';
import { css, useTheme } from '@emotion/react';

import FieldWrapper from '@common/components/FieldWrapper';

type AlertProps = {
  children: ReactNode;
};

const Alert = ({ children }: AlertProps) => {
  const theme = useTheme();
  return (
    <FieldWrapper
      border
      css={css`
        color: ${theme.colors.error};
      `}
      rightIcon='warning'
      rightIconProps={{
        size: 20,
      }}
      error
    >
      {children}
    </FieldWrapper>
  );
};

export default Alert;
