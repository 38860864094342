/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';
import { Field, FieldAttributes, FormikState } from 'formik';

import FieldWrapper from '@common/components/FieldWrapper';

type InputProps = {
  className?: string;
  icon?: string;
  size?: 'm' | 's';
};

const InputComponent: React.FC<
  {
    field: FieldAttributes<any>;
    form: FormikState<any>;
  } & InputProps
> = ({ field, form: { touched, errors }, className, icon, size = 'm', ...props }) => {
  const theme = useTheme();
  const error = touched[field.name] && errors[field.name];
  return (
    <FieldWrapper
      className={className}
      border
      leftIcon={icon}
      rightIcon={error ? 'warning' : undefined}
      rightIconProps={{
        size: 20,
      }}
      error={!!error}
    >
      <input
        css={css`
          flex: 1;
          background: transparent;
          border: 0;
          font-size: ${theme.fontSizes[size]};
          color: ${error ? theme.colors.errorText : theme.colors.textLight};
          &:focus {
            outline: none;
            color: #fff;

            &::placeholder {
              color: ${theme.colors.border};
            }
          }
          &::placeholder {
            color: ${error ? theme.colors.errorText : theme.colors.border};
            opacity: 0.8;
          }
        `}
        {...field}
        {...props}
      />
    </FieldWrapper>
  );
};

const Input: React.FC<FieldAttributes<any> & InputProps> = (props) => (
  <Field component={InputComponent} {...props} />
);

export default Input;
