import React, { ForwardedRef, forwardRef, useState } from 'react';
import { css, useTheme } from '@emotion/react';

import { Transition } from 'react-transition-group';

import ReactDOM from 'react-dom';

import { useEvent } from '@modules/event/hooks/useEvent';

import Avatar from '@modules/user/components/Avatar';
import EditProfile from '@modules/user/components/EditProfile';
import { HeaderProps } from '@common/components/Header';
import Body from '@common/components/Body';
import Icon from '@common/components/Icon';
import { closeMenu, openMenu } from '@common/store/menu';
import { useAppDispatch, useAppSelector } from '@common/store';
import { closeOverlay } from '@common/store/sideOverlay';
import { useUpdateUser } from '@modules/auth/hooks/useUpdateUser';

interface MobileHeaderProps extends HeaderProps {
  menuTimeline?: any;
}

const MobileHeader = forwardRef(
  (
    { title, liveMenu, mainMenu, menuTimeline }: MobileHeaderProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { updateUser, uploadAvatar } = useUpdateUser();
    const theme = useTheme();
    const event = useEvent();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.user);
    const showMenu = useAppSelector((state) => state.menu.showMenu);
    const mobileFullscreen = useAppSelector((state) => state.fullscreen.isMobileFullScreen);
    const hasScheduleOpen = useAppSelector((state) => state.sideoverlay.showOverlay);

    return (
      <>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: ${theme.colors.eventBackground};
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            z-index: 7000;
            padding: 0 1em;
            height: 65px;
            transition: all 0.1s ease;
            ${mobileFullscreen ? 'height: 0px; overflow: hidden;  border-bottom: unset' : ''}
          `}
        >
          {liveMenu || mainMenu ? (
            <div
              css={css`
                cursor: pointer;
              `}
              onClick={() => {
                if (showMenu) {
                  if (hasScheduleOpen) dispatch(closeOverlay());
                  dispatch(closeMenu());
                } else dispatch(openMenu());
              }}
            >
              <Icon icon={showMenu ? 'times' : 'menu'} color='#fff' size={24} />
            </div>
          ) : (
            <div
              css={css`
                width: 20px;
                height: 20px;
              `}
            />
          )}
          <div
            css={css`
              width: 1em;
            `}
          />
          {title && (
            <Body
              size='m'
              color='textLight'
              css={css`
                text-align: center;
                flex: 1;
              `}
            >
              {title}
            </Body>
          )}
          <div
            css={css`
              width: 1em;
            `}
          />
          <div>
            {user && (
              <div onClick={() => setOpenModal(!openModal)}>
                <Avatar
                  user={user}
                  size={40}
                  showLocation={event?.attendanceEnabled}
                  css={css`
                    cursor: pointer;
                  `}
                />
              </div>
            )}
          </div>
          {ReactDOM.createPortal(
            <EditProfile
              user={user}
              openModal={openModal}
              closeModal={() => setOpenModal(false)}
              onSubmit={(data) => {
                updateUser(data);
                setOpenModal(false);
              }}
              onUploadAvatar={(file) => file && uploadAvatar(file)}
            />,
            document.querySelector('#popupHolder') ?? document.body,
          )}
        </div>

        <Transition
          in={showMenu}
          addEndListener={() => {
            if (showMenu) menuTimeline.timeScale(1.5).play(0);
            else menuTimeline.timeScale(2).reverse(0);
          }}
        >
          <div
            ref={ref}
            css={css`
              position: fixed;
              top: -100%;
              left: 0;
              width: 100%;
              height: calc(100% - 65px);
              background: ${theme.colors.eventBackground};
              z-index: 1000;
              overflow-y: auto;
              transform: translateZ(0);
              padding: 20px 0;
              box-sizing: border-box;
            `}
          >
            {liveMenu}
            {mainMenu}
          </div>
        </Transition>
      </>
    );
  },
);

export default MobileHeader;
