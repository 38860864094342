import { Icon } from 'app/types/icons';

const Checkmark: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.91022 10.5585L9.90152 17.0716L20.1097 4.39886C20.4958 3.91951 21.1789 3.86436 21.6299 4.27612C22.0739 4.68158 22.1256 5.38796 21.746 5.86043L10.7268 19.5728C10.2862 20.1211 9.4914 20.1452 9.02162 19.6245L2.30601 12.1809C1.90071 11.7317 1.89765 11.0276 2.29903 10.5744C2.73293 10.0846 3.46764 10.0773 3.91022 10.5585Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Checkmark;
