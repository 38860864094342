import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { AppProps, AppContext } from 'next/app';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider, css, Global } from '@emotion/react';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { isClient } from '@nextempire/vle-common/lib/config';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Error handling
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';

import PusherProvider from '@common/components/PusherProvider';
import AuthScheduleWrapper from '@common/components/AuthScheduleWrapper';
import { QueryProvider } from '@common/components/QueryProvider';
import GlobalTheme from '@common/components/GlobalTheme';
import CookieConsent from '@common/components/CookieConsent';
import NotificationBase from '@common/components/alerts/NotificationBase';

import '@assets/fonts/fontello/css/fontello.css';
import '@assets/fonts/sofia-pro/sofia-pro.css';
import eventTheme from '@common/styles/eventTheme';

import { AuthInitializer } from '@modules/auth/hooks/useAuth';
import { EventProvider } from '@modules/event/hooks/useEvent';
import { SessionContextProvider } from '@modules/schedule/hooks/useSessionContext';

import transform, { EventResource } from '@modules/event/transforms/event';

import ErrorPage from '@pages/_error';

import fetch, { APIError } from '@common/utils/fetch';
import { absoluteUrl } from '@modules/auth/utils';
import '@common/utils/i18n';
import '@common/utils/timeago';
import { PlatformContextProvider } from '@common/hooks/usePlatform';
import useLanguage, { LanguageProvider } from '@common/hooks/useLanguage';
import { mediaQuery } from '@common/styles/mediaQuery';
import { ConvertedAlert } from '@common/utils/alerts';
import { store } from '@common/store';

import 'public/css/canvas.css';

type Props = AppProps & {
  event?: EventResource;
  error?: APIError;
  router: any;
};

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          // eslint-disable-next-line no-param-reassign
          frame.filename = frame.filename?.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  });
}

export default function App({ Component, pageProps = {}, event, error, router }: Props) {
  const [windowWidth, setWindowWidth] = useState((isClient && window.innerWidth) || 0);
  const { language } = useLanguage();

  const [notifications, setNotifications] = useState<ConvertedAlert[]>([]);

  const notificationListener = (ev: any) => {
    setNotifications((previous) => [...previous, ev.detail]);
  };

  const notificationUpdateListener = (ev: any) => {
    const session = ev.detail?.props?.session;
    if (!session?.id) return;

    setNotifications((previous) => {
      const exists = previous.some((alert) => alert.props?.session?.id === session.id);
      return exists
        ? [
            ...previous.filter((alert: ConvertedAlert) => alert.props?.session?.id !== session.id),
            ev.detail,
          ]
        : previous;
    });
  };

  useEffect(() => {
    document.addEventListener('add-notification', notificationListener);
    document.addEventListener('update-notification', notificationUpdateListener);
    return () => {
      document.removeEventListener('add-notification', notificationListener);
      document.removeEventListener('update-notification', notificationUpdateListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {}, [language]);

  useEffect(() => {
    if (event?.googleTrackingId) {
      ReactGA.initialize(event.googleTrackingId);
    }
  }, [event?.googleTrackingId]);

  if (event?.googleTrackingId && process.browser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  useEffect(() => {
    const listener = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [setWindowWidth]);

  useEffect(() => {
    if (event) {
      const base = document.location.origin;
      const manifest = {
        name: event.name,
        description: event.description,
        theme_color: event.themeSecondaryColor,
        background_color: event.themeBackgroundColor,
        display: 'standalone',
        start_url: `${base}/`,
        short_name: event.name,
        icons: [
          {
            src: `${base}/VIR_Icon_Only_192x192.png`,
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: `${base}/VIR_Icon_Only_256x256.png`,
            sizes: '256x256',
            type: 'image/png',
          },
          {
            src: `${base}/VIR_Icon_Only_384x384.png`,
            sizes: '384x384',
            type: 'image/png',
          },
          {
            src: `${base}/VIR_Icon_Only_512x512.png`,
            sizes: '512x512',
            type: 'image/png',
          },
        ],
        url_handlers: [
          {
            origin: base,
          },
        ],
      };

      const content = encodeURIComponent(JSON.stringify(manifest));
      const url = `data:application/manifest+json,${content}`;
      document.querySelector('#manifest')!.setAttribute('href', url);
    }
  }, [event]);

  if (error || !event) {
    return <ErrorPage statusCode={error?.status || 404} err={error} />;
  }

  return (
    <>
      <Global
        styles={css`
          html,
          body,
          #__next {
            height: 100%;
          }
        `}
      />
      <Provider store={store}>
        <EventProvider event={event}>
          <AuthInitializer>
            <QueryProvider>
              <PusherProvider>
                <ThemeProvider theme={eventTheme(event)}>
                  <SessionContextProvider>
                    <LanguageProvider>
                      <GlobalTheme />
                      {process.env.NEXT_PUBLIC_ASML_SERVER !== 'true' && <CookieConsent />}
                      <AuthScheduleWrapper />
                      <Head>
                        <title>{event.name}</title>
                        <link rel='icon' type='image/png' href={event.themeFaviconUrl} />
                      </Head>
                      <PlatformContextProvider value={windowWidth}>
                        <Component {...pageProps} event={event} key={router.route} />
                        <div
                          css={css`
                            position: fixed;
                            top: 65px;
                            right: 15px;
                            left: 15px;
                            display: flex;
                            flex-direction: column-reverse;
                            justify-content: flex-end;
                            z-index: 99999;

                            ${mediaQuery(
                              's',
                              css`
                                left: auto;
                                top: auto;
                                bottom: 20px;
                                width: 100%;
                                flex-direction: column;
                                max-width: 550px;
                              `,
                            )}
                          `}
                        >
                          {notifications
                            ?.filter((notification) => !notification.pinned)
                            ?.map?.((notification, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <NotificationBase key={index} {...notification} />
                            ))}
                          {notifications
                            ?.filter((notification) => notification.pinned)
                            ?.map?.((notification, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <NotificationBase key={index} {...notification} />
                            ))}
                        </div>
                      </PlatformContextProvider>
                    </LanguageProvider>
                  </SessionContextProvider>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </PusherProvider>
            </QueryProvider>
          </AuthInitializer>
        </EventProvider>
      </Provider>
    </>
  );
}

App.getInitialProps = async ({ ctx }: AppContext) => {
  const { host } = absoluteUrl(ctx.req);
  let scope = host;

  if (
    (typeof host === 'string' && host?.includes('lvh.me')) ||
    (typeof host === 'string' && host?.includes('vle.test')) ||
    (typeof host === 'string' && host?.includes('virtual-live-event.com')) ||
    (typeof host === 'string' && host?.includes('localhost'))
  ) {
    scope = host?.split('.')[0];
  }

  if (typeof host === 'string' && host?.includes('vle-frontend-pr')) {
    scope = process.env.NEXT_PUBLIC_REVIEW_APP_SUBDOMAIN;
  }

  try {
    const response = await fetch(`/events/${scope}`);

    return {
      event: transform.one(response.body),
    };
  } catch (error) {
    return { error };
  }
};
