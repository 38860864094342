/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';

type BodyProps = {
  className?: string;
  size?: 'm' | 's' | 'xs';
  weight?: 'normal' | 'bold';
  color?: 'textBody' | 'textLight' | 'primary';
};

const Body: React.FC<BodyProps> = ({
  className,
  size = 'm',
  weight = 'normal',
  color = 'textBody',
  children,
}) => {
  const theme = useTheme();

  return (
    <span
      className={className}
      css={css`
        color: ${theme.colors[color]};
        font-size: ${theme.fontSizes[size]};
        font-weight: ${theme.fontWeights[weight]};
        line-height: 1.25em;
        word-break: break-word;

        a {
          color: ${theme.colors[color]};
          font-size: ${theme.fontSizes[size]};
          font-weight: ${theme.fontWeights[weight]};
        }
      `}
    >
      {children}
    </span>
  );
};

export default Body;
