import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useCallback } from 'react';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import transform, { PageResource } from '@modules/event/transforms/page';
import { getStaleTime } from '@common/utils/staleTime';
import fetch, { APIError, retry } from '@common/utils/fetch';
import { useAppSelector } from '@common/store';

export const usePage = (pageId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery<PageResource, APIError>(
    ['page', event?.id, pageId],
    async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        const { body } = await fetch(`/events/${event?.id}/pages/${pageId}?lang=${i18n.language}`, {
          token: accessToken,
        });

        return transform.one(body);
      } catch (err) {
        throw err;
      }
    },
    {
      enabled: isAuthorized,
      ...getStaleTime(0.2), // TODO: see if we can use pusher to increase stale time
    },
  );
};

export const usePages = () => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);
  const { i18n } = useTranslation();

  return useQuery(
    ['pages', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/pages?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      // Refetch the data every 60 seconds
      refetchInterval: 60000,
      staleTime: 30000,
      cacheTime: 0,
      enabled: isAuthorized,
      retry,
    },
  );
};

export function usePrintPage(pageId: string) {
  const event = useEvent();
  const accessToken = getAccessToken();

  return useCallback(
    async (data: string): Promise<Blob> => {
      if (!event) return Promise.reject();

      try {
        let canvasHtml = data;
        canvasHtml = data.replace('action-buttons', 'action-buttons print-version');
        canvasHtml = canvasHtml.replace('vision-input', 'vision-input print-version');
        const randomNumber = Math.floor(Math.random() * 11);
        if (randomNumber > 5) {
          canvasHtml = canvasHtml.replace(
            'placeholder="VISION" style="',
            'placeholder="VISION" style="min-height: 3vw !important;',
          );
        } else {
          canvasHtml = canvasHtml.replace(
            'placeholder="VISION" style="',
            'placeholder="VISION" style="min-height: 2vw !important;',
          );
        }
        const response = await fetch(`/events/${event.id}/pages/${pageId}/print`, {
          method: 'POST',
          body: { html: canvasHtml, width: window.innerWidth, height: window.innerHeight },
          token: accessToken,
        });
        return response.response.blob();
      } catch (err: any) {
        console.log(err);
        throw new Error(err);
      }
    },
    [pageId, event, accessToken],
  );
}

export default { usePage, usePages, usePrintPage };
