import parse, { Attributes } from '@common/utils/parse';
import { parseUser } from '@modules/user/transforms/user';

export const parseVideoCallParticipant = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    videoCallId: attributes.id('videoCallId'),
    initiator: attributes.boolean('initiator'),
    rejectedAt: attributes.optional.string('rejectedAt'),
    missedAt: attributes.optional.string('missedAt'),
    missedSeenAt: attributes.optional.string('missedSeenAt'),
    user: attributes.optional.custom('user', (user: any) => {
      return parse.one(user, parseUser);
    }),
  };
};

export function one(data: any) {
  return parse.one(data, parseVideoCallParticipant);
}

export function many(data: any) {
  return parse.many(data, parseVideoCallParticipant);
}

export type VideoCallParticipantResource = ReturnType<typeof one>;
export type VideoCallParticipant = Omit<VideoCallParticipantResource, 'id'>;

export default { one, many };
