/* eslint-disable security/detect-object-injection */
import { css, useTheme } from '@emotion/react';

type LabeledProps = {
  label: string;
  size?: 'm' | 's';
};

const Labeled: React.FC<LabeledProps> = ({ label, children, size = 'm' }) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-bottom: 1.5em;
      `}
    >
      <span
        css={css`
          font-size: ${theme.fontSizes[size]};
          color: ${theme.colors.textLight};
          margin-bottom: 0.75em;
        `}
      >
        {label}
      </span>
      {children}
    </div>
  );
};

export default Labeled;
