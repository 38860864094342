import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import pluralize from 'pluralize';
import { languageUrl } from '@nextempire/vle-common/lib/utils';

import { getAccessToken } from '@modules/auth/hooks/useAuth';
import { useEventId } from '@modules/event/hooks/useEvent';
import { MetaResource } from '@common/transforms/meta';
import { getStaleTime } from '@common/utils/staleTime';
import fetch, { APIError, retry } from '@common/utils/fetch';
import { useAppSelector } from '@common/store';

export const useInit = () => {
  const { i18n } = useTranslation();
  const accessToken = getAccessToken();
  const eventId = useEventId();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return { accessToken, language: i18n.language, eventId, isAuthorized };
};

export const useResource = <T>(
  resourceName: string,
  resourceId: string,
  transformFunction: (data: unknown) => T,
  staleTime?: number,
) => {
  const { accessToken, language, eventId, isAuthorized } = useInit();
  const resourceNamePlural = pluralize(resourceName);

  return useQuery<T, APIError>(
    [resourceName, eventId, resourceId],
    async () => {
      const { body } = await fetch(
        languageUrl(`/events/${eventId}/${resourceNamePlural}/${resourceId}`, language),
        {
          token: accessToken,
        },
      );

      return transformFunction(body);
    },
    {
      ...getStaleTime(staleTime ?? 5), // TODO: check stale time, used for single session retrieval
      enabled: isAuthorized,
    },
  );
};

export const useResources = <T, S = { items: T[]; meta: MetaResource }>(
  resourceName: string,
  transformFunction: (data: unknown) => S,
  options: {
    pagination?: { page: number; limit: number };
    date?: string;
    staleTime?: number;
    startDate?: string;
    endDate?: string;
    timezone?: string;
    search?: string;
    path?: string;
  } = {},
) => {
  const { accessToken, language, eventId, isAuthorized } = useInit();
  const { pagination, date, staleTime, startDate, endDate, timezone, search, path } = options;
  const query = queryString.stringify({
    ...pagination,
    date,
    startDate,
    endDate,
    timezone,
    search: search || undefined,
  });
  const resourceNamePlural = pluralize(resourceName);
  return useQuery<S, APIError>(
    [
      resourceNamePlural,
      eventId,
      pagination?.page,
      pagination?.limit,
      date,
      startDate,
      endDate,
      timezone,
      search,
      path,
    ].filter(Boolean),
    async () => {
      const { body } = await fetch(
        languageUrl(`/events/${eventId}/${path ?? resourceNamePlural}?${query}`, language),
        {
          token: accessToken,
        },
      );

      return transformFunction(body);
    },
    {
      keepPreviousData: !!pagination,
      enabled: !!eventId && isAuthorized,
      ...getStaleTime(staleTime ?? 5), // TODO: check stale time
      retry,
    },
  );
};

export const useInfiniteResources = <T>(
  resourceName: string,
  transformFunction: (data: unknown) => { items: T[]; meta: MetaResource },
  options: {
    pagination?: { limit: number };
    staleTime?: number;
    search?: string;
    path?: string;
  } = {},
) => {
  const { accessToken, language, eventId } = useInit();
  const { pagination, staleTime, search, path } = options;
  const query = queryString.stringify({
    ...pagination,
    search: search || undefined,
  });
  const resourceNamePlural = pluralize(resourceName);

  return useInfiniteQuery<{ items: T[]; meta: MetaResource }, APIError>(
    [resourceNamePlural, eventId, pagination?.limit, search, path].filter(Boolean),
    async ({ pageParam = 1 }) => {
      const { body } = await fetch(
        languageUrl(
          `/events/${eventId}/${path ?? resourceNamePlural}?page=${pageParam}&${query}`,
          language,
        ),
        {
          token: accessToken,
        },
      );

      return transformFunction(body);
    },
    {
      ...getStaleTime(staleTime ?? 5), // TODO: check stale time
      keepPreviousData: true,
      enabled: !!eventId,
      getNextPageParam: (lastPage) =>
        lastPage.meta.currentPage === lastPage.meta.totalPages
          ? undefined
          : lastPage.meta.currentPage + 1,
    },
  );
};

export const useCreateResource = <T>(resourceName: string) => {
  const queryClient = useQueryClient();
  const { accessToken, language, eventId } = useInit();
  const resourceNamePlural = pluralize(resourceName);

  return useMutation(
    (values: T) => {
      return fetch(languageUrl(`/events/${eventId}/${resourceNamePlural}`, language), {
        method: 'POST',
        body: values,
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resourceNamePlural);
      },
    },
  );
};

export const useUpdateResource = <T>(resourceName: string, resourceId?: string) => {
  const queryClient = useQueryClient();
  const { accessToken, language, eventId } = useInit();
  const resourceNamePlural = pluralize(resourceName);

  return useMutation(
    (values: T) => {
      return fetch(
        languageUrl(`/events/${eventId}/${resourceNamePlural}/${resourceId}`, language),
        {
          method: 'PUT',
          body: values,
          token: accessToken,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resourceNamePlural);
      },
    },
  );
};

export const useDeleteResource = (resourceName: string) => {
  const queryClient = useQueryClient();
  const { accessToken, eventId } = useInit();
  const resourceNamePlural = pluralize(resourceName);

  return useMutation(
    (id: string) => {
      return fetch(`/events/${eventId}/${resourceNamePlural}/${id}`, {
        method: 'DELETE',
        token: accessToken,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resourceNamePlural);
      },
    },
  );
};

export default {
  useCreateResource,
  useInfiniteResources,
  useUpdateResource,
  useDeleteResource,
  useInit,
};
