import { Icon } from 'app/types/icons';

const Users: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5146 14.8895C13.1568 13.5956 12.3834 12.4602 11.3173 11.6637C10.2511 10.8671 8.95345 10.4551 7.63032 10.4931C6.30718 10.5311 5.03448 11.0168 4.01438 11.8732C2.99428 12.7297 2.28528 13.9076 2 15.2199C2.75805 15.9699 3.66019 16.5531 4.65014 16.9333C5.64008 17.3135 6.69658 17.4824 7.7537 17.4296C8.84024 17.473 9.92244 17.2672 10.9198 16.8274C11.9172 16.3876 12.8041 15.7252 13.5146 14.8895'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M13.5146 14.8895C13.1568 13.5956 12.3834 12.4602 11.3173 11.6637C10.2511 10.8671 8.95345 10.4551 7.63032 10.4931C6.30718 10.5311 5.03448 11.0168 4.01438 11.8732C2.99428 12.7297 2.28528 13.9076 2 15.2199C2.75805 15.9699 3.66019 16.5531 4.65014 16.9333C5.64008 17.3135 6.69658 17.4824 7.7537 17.4296C8.84024 17.473 9.92244 17.2672 10.9198 16.8274C11.9172 16.3876 12.8041 15.7252 13.5146 14.8895V14.8895Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M10.5711 5.86047C10.5711 5.29472 10.4059 4.74168 10.0963 4.27128C9.78672 3.80088 9.34671 3.43424 8.8319 3.21774C8.3171 3.00124 7.75063 2.94459 7.20411 3.05496C6.6576 3.16534 6.1556 3.43777 5.76158 3.83781C5.36757 4.23786 5.09925 4.74754 4.99054 5.30242C4.88183 5.8573 4.93762 6.43244 5.15086 6.95512C5.3641 7.47781 5.72521 7.92455 6.18852 8.23886C6.65183 8.55317 7.19653 8.72094 7.75375 8.72094C8.50096 8.72094 9.21756 8.41957 9.74592 7.88313C10.2743 7.34668 10.5711 6.61911 10.5711 5.86047Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M7.7546 8.72094C9.31058 8.72094 10.572 7.44026 10.572 5.86047C10.572 4.28068 9.31058 3 7.7546 3C6.19863 3 4.93726 4.28068 4.93726 5.86047C4.93726 7.44026 6.19863 8.72094 7.7546 8.72094Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M22 18.4511C21.6421 17.1572 20.8687 16.0218 19.8026 15.2253C18.7365 14.4288 17.4388 14.0168 16.1157 14.0547C14.7925 14.0927 13.5198 14.5785 12.4997 15.4349C11.4796 16.2913 10.7706 17.4692 10.4854 18.7815C11.2438 19.5319 12.1466 20.1153 13.1372 20.4953C14.1278 20.8754 15.185 21.0439 16.2427 20.9903C17.3285 21.0333 18.41 20.8273 19.4067 20.3877C20.4034 19.9481 21.2898 19.2862 22 18.4511'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M22 18.4511C21.6421 17.1572 20.8687 16.0218 19.8026 15.2253C18.7365 14.4288 17.4388 14.0168 16.1157 14.0547C14.7925 14.0927 13.5198 14.5785 12.4997 15.4349C11.4796 16.2913 10.7706 17.4692 10.4854 18.7815C11.2438 19.5319 12.1466 20.1153 13.1372 20.4953C14.1278 20.8754 15.185 21.0439 16.2427 20.9903C17.3285 21.0333 18.41 20.8273 19.4067 20.3877C20.4034 19.9481 21.2898 19.2862 22 18.4511V18.4511Z'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M19.0601 9.4221C19.06 8.85661 18.8947 8.30387 18.5851 7.83376C18.2756 7.36365 17.8357 6.99727 17.3211 6.78095C16.8065 6.56464 16.2403 6.50808 15.694 6.61845C15.1478 6.72881 14.646 7.00114 14.2522 7.401C13.8583 7.80086 13.5901 8.3103 13.4814 8.86492C13.3727 9.41953 13.4284 9.99441 13.6415 10.5169C13.8545 11.0394 14.2154 11.486 14.6784 11.8003C15.1414 12.1145 15.6858 12.2824 16.2428 12.2826C16.99 12.2826 17.7066 11.9812 18.235 11.4448C18.7633 10.9083 19.0601 10.1807 19.0601 9.4221'
        fill={color}
        strokeWidth={0}
      />
      <path
        d='M19.0601 9.4221C19.06 8.85661 18.8947 8.30387 18.5851 7.83376C18.2756 7.36365 17.8357 6.99727 17.3211 6.78095C16.8065 6.56464 16.2403 6.50808 15.694 6.61845C15.1478 6.72881 14.646 7.00114 14.2522 7.401C13.8583 7.80086 13.5901 8.3103 13.4814 8.86492C13.3727 9.41953 13.4284 9.99441 13.6415 10.5169C13.8545 11.0394 14.2154 11.486 14.6784 11.8003C15.1414 12.1145 15.6858 12.2824 16.2428 12.2826C16.99 12.2826 17.7066 11.9812 18.235 11.4448C18.7633 10.9083 19.0601 10.1807 19.0601 9.4221V9.4221Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default Users;
