import { Global, css, useTheme } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import { readableColor } from 'polished';

const GlobalTheme = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        ${emotionNormalize}
        html {
          display: flex;
          flex-direction: column;
          min-height: 100%;
        }
        body {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0;
          margin: 0;
          background: ${theme.colors.eventBackground};
          color: ${readableColor(theme.colors.eventBackground)};
          font-size: 16px;
          min-height: 100%;
          font-family: SofiaPro, Helvetica, Arial, sans-serif;
        }

        #__next {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        a:link {
          /* text-decoration: none; */
        }

        a:visited {
          text-decoration: none;
        }

        a:hover {
          text-decoration: none;
        }

        a:active {
          text-decoration: none;
        }

        .icon-ic_slido:before {
          margin-left: -0.1em;
        }

        .icon-ic_videocall:before {
          margin-left: -0.1em;
          font-size: 0.9em;
        }
      `}
    />
  );
};

export default GlobalTheme;
