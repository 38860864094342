import parse, { Attributes } from '@common/utils/parse';

export const PageTemplate = {
  ATTENDEES: 'ATTENDEES',
  IFRAME: 'IFRAME',
  LANDING: 'LANDING',
  LIVE: 'LIVE',
  VIDEO_ON_DEMAND: 'VIDEO_ON_DEMAND',
  VIRTUAL_VENUE: 'VIRTUAL_VENUE',
  BREAKOUT: 'BREAKOUT',
  BASIC: 'BASIC',
  SCHEDULE: 'SCHEDULE',
  CANVAS: 'CANVAS',
};

function transformStringArray(data: any): string[] {
  if (!Array.isArray(data)) return [];
  return data.filter((value: any) => typeof value === 'string') as string[];
}

export const parsePage = (attributes: Attributes) => {
  return {
    id: attributes.id('id'),
    slug: attributes.string('slug'),
    title: attributes.string('title'),
    template: attributes.enum<typeof PageTemplate>('template', PageTemplate),
    parameters: attributes.object('parameters'),
    roles: attributes.custom('roles', transformStringArray),
    enabled: attributes.boolean('enabled'),
    visibleFrom: attributes.optional.date('visibleFrom'),
    visibleTill: attributes.optional.date('visibleTill'),
    visibleInMenu: attributes.boolean('visibleInMenu'),
    groupChatEnabled: attributes.boolean('groupChatEnabled'),
    clapHandsEnabled: attributes.optional.boolean('clapHandsEnabled'),
  };
};

export function one(data: any) {
  return parse.one(data, parsePage);
}

export function many(data: any) {
  return parse.many(data, parsePage);
}

export type PageResource = ReturnType<typeof one>;
export type Page = Omit<PageResource, 'id'>;

export default { one, many };
