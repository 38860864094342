import { Icon } from 'app/types/icons';

const NoPic: React.FC<Icon> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM12 1.26316C6.07021 1.26316 1.26316 6.07021 1.26316 12C1.26316 14.8301 2.35815 17.4045 4.14759 19.3226C5.14955 15.924 8.23257 13.5211 11.817 13.4197C15.5935 13.313 18.9587 15.7885 19.9813 19.4255L19.9271 19.2417C21.6723 17.3324 22.7368 14.7905 22.7368 12C22.7368 6.07021 17.9298 1.26316 12 1.26316ZM12 4.35537C14.4337 4.35537 16.4065 6.32824 16.4065 8.76189C16.4065 11.1956 14.4337 13.1684 12 13.1684C9.56634 13.1684 7.59347 11.1956 7.59347 8.76189C7.59347 6.32824 9.56634 4.35537 12 4.35537Z'
        fill={color}
        strokeWidth={0}
      />
    </svg>
  );
};

export default NoPic;
