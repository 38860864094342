import { useQuery } from 'react-query';

import { useTranslation } from 'react-i18next';

import useEvent from '@modules/event/hooks/useEvent';
import { getAccessToken } from '@modules/auth/hooks/useAuth';

import transform from '@modules/event/transforms/customField';
import { useAppSelector } from '@common/store';
import { getStaleTime } from '@common/utils/staleTime';
import fetch from '@common/utils/fetch';

export const useCustomField = (customFieldId: string) => {
  const event = useEvent();
  const accessToken = getAccessToken();
  const { i18n } = useTranslation();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  return useQuery(
    ['customField', event?.id, customFieldId],
    async () => {
      const { body } = await fetch(
        `/events/${event?.id}/custom-fields/${customFieldId}?lang=${i18n.language}`,
        {
          token: accessToken,
        },
      );

      return transform.one(body);
    },
    {
      ...getStaleTime(5),
      enabled: isAuthorized,
    },
  );
};

export const useCustomFields = () => {
  const event = useEvent();
  const { i18n } = useTranslation();
  const accessToken = getAccessToken();

  return useQuery(
    ['customFields', event?.id],
    async () => {
      const { body } = await fetch(`/events/${event?.id}/custom-fields?lang=${i18n.language}`, {
        token: accessToken,
      });

      return transform.many(body);
    },
    {
      ...getStaleTime(5),
    },
  );
};

export default {
  useCustomField,
  useCustomFields,
};
