import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { ConvertedAlert, ALERT_TYPES } from '@common/utils/alerts';
import Toast from '@common/components/alerts/Toast';
import Reminder from '@common/components/alerts/Reminder';
import Call from '@common/components/alerts/Call';
import DoNotDisturb from '@common/components/alerts/DoNotDisturb';

const NotificationBase: React.FC<ConvertedAlert> = (props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (!props.decay) return;
    setTimeout(() => {
      setVisible(false);
    }, props.decayTime ?? 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setVisible(false);
  };

  const getBody = () => {
    switch (props.type) {
      case ALERT_TYPES.TOAST:
        return <Toast {...props.props} />;
      case ALERT_TYPES.REMINDER:
        return <Reminder {...props.props} onClose={handleClose} />;
      case ALERT_TYPES.CALL:
        return <Call {...props.props} />;
      case ALERT_TYPES.DO_NOT_DISTURB:
        return <DoNotDisturb {...props.props} />;
      default:
        return null;
    }
  };

  if (!visible || !getBody()) return null;
  return (
    <div
      css={css`
        align-self: flex-end;
        width: auto;
        max-width: 100%;
        margin-top: 15px;
      `}
    >
      {getBody()}
    </div>
  );
};

export default NotificationBase;
