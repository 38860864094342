import createLogger from '@common/utils/createLogger';

import { createTransformers } from './transform';
import { compact } from './utils';

const logger = createLogger('parse');

export * from './error';
export * from './transform';
export * from './utils';

export type Attributes = ReturnType<typeof createTransformers>;
export type ParseFn<Result extends any> = (attributes: Attributes) => Result;

export interface ParseManyOptions {
  ignore: boolean;
}

export function one<Result extends any>(object: any, transform: ParseFn<Result>): Result {
  return compact(transform(createTransformers(object)));
}

export function many<Result extends any>(
  object: any,
  transform: (attributes: Attributes) => Result,
  options: ParseManyOptions = { ignore: true },
): Result[] {
  if (typeof object === 'object' && Array.isArray(object)) {
    return object
      .map((data: any) => {
        try {
          return compact(transform(createTransformers(data)));
        } catch (error) {
          if (options.ignore && error.isAPIParseError) {
            logger.warning('Failed to parse collection resource', error);
            return undefined;
          }

          throw error;
        }
      })
      .filter(Boolean) as Result[];
  }

  return [];
}

export default { one, many };
